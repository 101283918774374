import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { depositHistory } from "../services/apiServices";
import { depositHistoryUrl } from "../services/urls";

const initialState = {
    allHistory: "",
    isLoading: false,
	isSuccess: false,
	message: "",
};

// export const fetchInvestor = createAsyncThunk(
// 	getUser,
// 	async (email, thunkAPI) => {
// 		try {
// 			const token = thunkAPI.getState().userReducer.user.token;
// 			return await fetchUser(email, token);
// 		} catch (error) {
// 			const message = error.message;
// 			return thunkAPI.rejectWithValue(message);
// 		}
// 	}
// );
export const fetchAllDepositHistory = createAsyncThunk(
	depositHistoryUrl,
	async (email, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await depositHistory(email, token);
		} catch (error) {
			const message = error.message;
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const allDepositHistorySlice = createSlice({
	name: "deposithistory",
	initialState,
	reducers: {
		resetDepositHistory: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllDepositHistory.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchAllDepositHistory.fulfilled, (state, action) => {
            state.allHistory = action?.payload;
			state.isLoading = false;
			state.isSuccess = true;
		});
		builder.addCase(fetchAllDepositHistory.rejected, (state, action) => {
			console.table(action.payload);
			state.isError = true;
			state.isLoading = false;
			state.message = action.payload;
		});
	},
});

export const { resetDepositHistory } = allDepositHistorySlice.actions;
export default allDepositHistorySlice.reducer;
