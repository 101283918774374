import React, { useEffect, useState } from "react";
import "./history.scss";
import { Space, Spin, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchAllDepositHistory } from "../../features/depositHistorySlice";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import useAdmin from "../../hooks/useAdmin";

const WithdrawalHistoryList = ({ title }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  //   const { isError, message, allHistory, isLoading } = useSelector(
  //     (state) => state.allDepositHistorySlice
  //   );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const {
    handleGetWithdrawals,
    getwithdrawalsData,
    handleUpdateUserWithdrawal,
  } = useAdmin();

  useEffect(() => {
    // dispatch(fetchAllDepositHistory(user.email));
    handleGetWithdrawals();
  }, []);

  const myWithdrawals = getwithdrawalsData?.data?.data?.filter((data, idx) => {
    return data.email === user.email;
  });
  console.log(myWithdrawals);

  return (
    <div className="dhl-container">
      {getwithdrawalsData?.loading ? (
        <div
          className="example"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>s/n</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Payto</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {myWithdrawals?.reverse()?.map((data, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{data.ref}</td>
                  <td>{formatter.format(data.amount)}</td>
                  <td>{data.payto}</td>
                  <td>
                    <span
                      // className="approve-btn"
                      className={`${
                        data?.status === "pending" ? "pending" : "confirmed"
                      } approve-btn`}
                    >
                      {data?.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {!getwithdrawalsData?.loading &&
            getwithdrawalsData?.data?.length === 0 && (
              <h2 style={{ textAlign: "center", marginTop: 100 }}>
                No Withdrawal History
              </h2>
            )}
        </>
      )}
      {/* <Table dataSource={dataSource} columns={columns} />; */}
    </div>
  );
};

export default WithdrawalHistoryList;
