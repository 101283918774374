import React, { useEffect, useState } from "react";
import "./profileDetails.scss";

const AdminWalletDetails = ({ commission, main, roi }) => {
  const [active, setActive] = useState("tab1");

  const handleTab1 = () => {
    // update the state to tab1
    setActive("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActive("tab2");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActive("tab3");
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <div className="profile-wallets">
      <h3> Wallets </h3>
      <div className="pw-amount">
        {active === "tab1" && <p>{commission === undefined ? "$0.00" : formatter.format(commission)}</p>}
        {active === "tab2" && <p>{main === undefined ? "$0.00" : formatter.format(main)}</p>}
        {active === "tab3" && <p>{roi === undefined ? "$0.00": formatter.format(roi)}</p>}
      </div>
      <div className="pw-type">
        <span
          className={`${active === "tab1" && "active"} pw-type-box`}
          onClick={handleTab1}
        >
          commission Account
        </span>
        <span
          className={`${active === "tab2" && "active"} pw-type-box`}
          onClick={handleTab2}
        >
          Main Account
        </span>
        <span
          className={`${active === "tab3" && "active"} pw-type-box`}
          onClick={handleTab3}
        >
          ROI Account
        </span>
      </div>
    </div>
  );
};

export default AdminWalletDetails;
