import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUser, allUsers } from "../services/apiServices";
import { getUser, allUsersUrl } from "../services/urls";

const initialState = {
    allUsers: "",
    isLoading: false,
	isSuccess: false,
	message: "",
};

export const fetchInvestor = createAsyncThunk(
	getUser,
	async (email, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await fetchUser(email, token);
		} catch (error) {
			const message = error.message;
			return thunkAPI.rejectWithValue(message);
		}
	}
);
export const fetchAllInvestor = createAsyncThunk(
	allUsersUrl,
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await allUsers(data, token);
		} catch (error) {
			const message = error.message;
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const allUserSlice = createSlice({
	name: "investor",
	initialState,
	reducers: {
		resetInvestor: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllInvestor.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchAllInvestor.fulfilled, (state, action) => {
            state.allUsers = action?.payload?.data;
			state.isLoading = false;
			state.isSuccess = true;
		});
		builder.addCase(fetchAllInvestor.rejected, (state, action) => {
			console.table(action.payload);
			state.isError = true;
			state.isLoading = false;
			state.message = action.payload;
		});
	},
});

export const { resetInvestor } = allUserSlice.actions;
export default allUserSlice.reducer;
