import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="p-5 bg-light mt-5">
      <div className="container">
        <div className="row justify-content-evenly">
          <div className="col-lg-4 text-center">
            <img src="/images/BCN 1.png" alt="" />
            <h4 className="fw-bold">contact info</h4>
            <p className="">
              508 Zhongshan Avenue, 14th Floor Block 8, Hankou Macau
            </p>
            <p className="my-1">admin@TradeGPT.site</p>
            <p className="my-1">www.TradeGPT.site</p>
          </div>
          {/* <!-- <div className="col-lg-2 mt-3 links text-center">
          <h5 className="link">Links</h5>
          <ul className="text-center p-0">
            <li className=""><a href="#">Home</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Sponsors</a></li>
            <li><a href="#">Testimonies</a></li>
            <li><a href="#">Blog</a></li>
          </ul>
        </div>
        <div className="col-lg-3 mt-3 links text-center">
          <h5 className="fw-bold">Enquires</h5>
          <ul className="p-0">
            <li><a href="#"> TradeGPT@enquires.com</a></li>
            <li><a href="#">home@bcn.com</a></li>
            <li><a href="#">bcnworld@app.com</a></li>
          </ul>
        </div> --> */}
          <div className="col-lg-3 mt-3 text-center">
            <h6 className="fw-bold mb-5">Connnect to our Socials</h6>
            <ul className="list-inline socials d-lg-flex justify-content-evenly">
              <li className="list-inline-item">
                <a href="#" className="p-2 connect rounded-circle">
                  <i className="fa-brands fa-facebook"></i>{" "}
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="p-2 connect rounded-circle">
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="p-2 connect rounded-circle">
                  <i className="fa-brands fa-instagram text-warning"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#" className="p-2 connect rounded-circle">
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
