import React from "react";
import { Link } from "react-router-dom";

export default function EmailConfirm() {
  return (
    <div
      className="email-confirm-container"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="email-confirm-row"
        style={{ width: "80%", margin: "0 auto" }}
      >
        <div className="email-confirm-content" style={{ textAlign: "center" }}>
          <h1 style={{ fontWeight: 500, fontFamily: "Josefin Sans" }}>Registration Successful!</h1>
          <h2> Please check your email for confirmation </h2>
        </div>
        <div style={{ textAlign: "center" }}>
          Verified your email? {""}
          <Link
            to="/signin"
            reloadDocument
            style={{ textDecoration: "none", color: "#0b5ba1" }}
          >
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
}
