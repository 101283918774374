import axios from "axios";
import {
  loginUrl,
  registerUrl,
  getUser,
  depositUrl,
  withdrawUrl,
  allUsersUrl,
  updateWalletUrl,
  allDepositUrl,
  allWithdrawalUrl,
  depositHistoryUrl,
  withdrawalHistoryUrl,
  approveDepositUrl,
  createNewAdminUrl,
  payRoiUrl,
  payCommissionUrl,
} from "./urls";

export const registerUser = async (data) => {
  let response = await axios({
    method: "POST",
    url: registerUrl,
    data,
  });
  return response.data;
};

export const loginUser = async (data) => {
  let response = await axios({
    method: "POST",
    url: loginUrl,
    data,
  });
  return response.data;
};

export const fetchUser = async (email, token) => {
  let response = await axios({
    method: "GET",
    url: `${getUser}${email}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deposit = async (data, token) => {
  let response = await axios({
    method: "POST",
    url: depositUrl,
    data,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
export const withdrawal = async (data, token) => {
  let response = await axios({
    method: "POST",
    url: withdrawUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const allUsers = async (data, token) => {
  let response = await axios({
    method: "GET",
    url: allUsersUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const updateUserWallet = async (data, token) => {
  let response = await axios({
    method: "PATCH",
    url: updateWalletUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const allDeposit = async (data, token) => {
  let response = await axios({
    method: "GET",
    url: allDepositUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const allWithdrawal = async (data, token) => {
  let response = await axios({
    method: "GET",
    url: allWithdrawalUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const depositHistory = async (email, token) => {
  let response = await axios({
    method: "GET",
    url: `${depositHistoryUrl}${email}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const withdrawalHistory = async (email, token) => {
  let response = await axios({
    method: "GET",
    url: `${withdrawalHistoryUrl}${email}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const approveDeposit = async (ref, email, token) => {
  let response = await axios({
    method: "PATCH",
    url: `${approveDepositUrl}${ref}/${email}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const registerAdmin = async (data, token) => {
  let response = await axios({
    method: "POST",
    url: createNewAdminUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const payRoi = async (data, token) => {
  let response = await axios({
    method: "POST",
    url: payRoiUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const payCommission = async (data, token) => {
  let response = await axios({
    method: "POST",
    url: payCommissionUrl,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};