import React, { useState, useEffect } from "react";
import "./DepositAndWithdraw.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDeposit } from "../../../features/depositSlice";

export default function Deposit() {
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { amountToDeposit } = useSelector((state) => state.depositSlice);
  console.log(amountToDeposit);

  const open = () => {
    navigate(`/deposit-contd`);
  };

  useEffect(() => {
    if (parseInt(amountToDeposit) < Number(500) || !parseInt(amountToDeposit)) {
      setError("500 USD minimum deposit");
    }
  }, [amountToDeposit]);
  useEffect(() => {
    if (parseInt(amountToDeposit) >= Number(500)) {
      setError(null);
    }
  }, [amountToDeposit]);
  return (
    <div className="deposit-container">
      <div className="deposit-row">
        <div className="deposit-form">
          <div className="di-card">
            <span>
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => navigate("/profile")}
              />
            </span>
            <div className="deposit-header">
              <h3>DEPOSIT</h3>
            </div>
            <div className="deposit-form-item">
              <label>Payment Currency</label>
              <input
                type="text"
                value={"Bitcoin"}
                readOnly
                className="readonly"
              />
            </div>

            <div className="deposit-form-itm">
              <label htmlFor="deposit_amount">
                Amount USD <span className="required">required</span>
              </label>
              <input
                type="number"
                name="deposit_amount"
                min={"500"}
                placeholder="5000"
                style={{ borderColor: "#1e82c5" }}
                onChange={(e) => dispatch(setDeposit(e.target.value))}
                value={amountToDeposit}
              />
            </div>
            <small style={{ color: "#FF1818" }}>{error}</small>
            {/* <Link to="/deposit-contd"> */}
            <button
              className="deposit-next"
              onClick={() => open()}
              disabled={error !== null}
            >
              PROCEED
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
