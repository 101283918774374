import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import investorSlice from "../features/investorSlice";
import depositSlice from "../features/depositSlice";
import withdrawSlice from "../features/withdrawSlice";
import allUserSlice from "../features/adminSlice";
import updateWalletSlice from "../features/walletSlice";
import allDepositSlice from "../features/allDepositSlice";
import allWithdrawalSlice from "../features/allWithdrawalSlice";
import allDepositHistorySlice from "../features/depositHistorySlice";
import adminApproveDepositSlice from "../features/Deposits/approveDepositsSlice";
import newAdminSlice from "../features/NewAdmin/NewAdminSlice";
import updateRoiSlice from "../features/updateRoi/updateRoiSlice";
import updateCommissionSlice from "../features/updateRoi/updateCommissionSlice";
// import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    userReducer: persistedReducer,
    investorSlice,
    depositSlice,
    withdrawSlice,
    allUserSlice,
    updateWalletSlice,
    allDepositSlice,
    allWithdrawalSlice,
    allDepositHistorySlice,
    adminApproveDepositSlice,
    newAdminSlice,
    updateRoiSlice,
    updateCommissionSlice
  },
  devTools: process.env.NODE_ENV !== "production",
});
