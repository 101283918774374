import React, { useEffect, useState } from "react";
import "./admin.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import NotificationsList from "../../components/Admin/Notifications/notificationsList";
import CreateAdminBody from "../../components/Admin/CreateAdmin/CreateAdminBody";

const CreateAdmin = () => {
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Create Admin"} visible={false} />
      <main>
        <CreateAdminBody />
      </main>
    </div>
  );
};

export default CreateAdmin;
