import React, { useEffect, useState } from "react";
import "./admin.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import SiteSettingsBody from "../../components/Admin/Settings/SiteSettings";

const SiteSettings = () => {
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Site Settings"} visible={false} />
      <main>
        <SiteSettingsBody />
      </main>
    </div>
  );
};

export default SiteSettings;
