import React, { useEffect, useState } from "react";
import "./admin.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import DepositReqList from "../../components/Admin/Transactions/DepositReqList";

const DepositReq = () => {
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Deposit Requests"} visible={false} />
      <main>
        <DepositReqList />{" "}
      </main>
    </div>
  );
};

export default DepositReq;
