import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Context/UserRegister";
import "../../components/SignUp/Signup";
import "./usersTransactions.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Outlet, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Space, Spin } from "antd";
// import { toast } from "react-toastify";
import "antd/es/spin/style/css";
//we used context just for user form info, we would be upgrading to redux soon
import { registerNewAdmin } from "../../features/NewAdmin/NewAdminSlice";
import useAdmin from "../../hooks/useAdmin";
import { setProfile } from "../../features/userSlice";

export default function UpdateProfile() {
  //state for local component to track error---- validation
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const { handleUpdateProfile, updateProfileData } = useAdmin();
  const { user } = useSelector((state) => state.userReducer);

  console.log(user)
  //   useEffect(() => {
  //     if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     }
  //   }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let phoneRegex =
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    if (!values.firstName) {
      errors.firstName = "First Name is Required";
    } else if (values.firstName.length < 3) {
      errors.firstName = "First Name should be more than 3";
    }

    if (!values.otherName) {
      errors.otherName = "Last Name is Required";
    } else if (values.otherName.length < 3) {
      errors.otherName = "Last Name should be more than 3";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is Required";
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = "Invalid Phone Number";
    }
    // check for no errors before allowing to send

    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
    return errors;
  };

  ////////////////////////////////
  const [formData1, setFormData1] = useState({
    firstName: user.firstName,
    otherName: user.otherName,
    phone: user.phone,
    email: user.email,
    token: user.token
  });
  const handleInputs = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };
  const clearInputs = () => {
    setFormData1({
      firstName: "",
      otherName: "",
      phone: "",
    });
  };

  console.log(formData1);
  console.log(isSubmit);
  const handleSubmit = (e) => {
    const newData = {
      firstName: formData1.firstName,
      otherName: formData1.otherName,
      email: user.email,
      phone: formData1.phone,
    };
    handleValidation();
    if (isSubmit) {
      handleUpdateProfile(newData);
    }
  };

  //validation
  const handleValidation = () => {
    setFormErrors(validate(formData1));
  };

  useEffect(() => {
    if (updateProfileData?.response?.status === 201) {
      setMsg("Success! Profile updated");
      setTimeout(() => {
        setMsg("");
      }, 2000);
      dispatch(setProfile(formData1))
    }
  }, [updateProfileData?.response]);
  console.log(updateProfileData);

  return (
    <div className="user-transactions-container">
      <div className="change-password-nav">
        <span>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => navigate("/profile")}
          />
        </span>
        <h3> Update Profile</h3>
      </div>
      <div className="signup-container2">
        <div className="signup-row">
          <form onSubmit={handleSubmit}>
            <div className="signup-form">
              <div className="signup-name">
                <label htmlFor="firstName">First Name</label>
                <input
                  value={formData1.firstName}
                  type="text"
                  name="firstName"
                  id="signup-first-name"
                  placeholder="first name"
                  onChange={handleInputs}
                  onBlur={handleValidation}
                  style={{ borderColor: "#1e82c5" }}
                />
                <small className="error">{formErrors.firstName}</small>
              </div>
              <div className="signup-name">
                <label htmlFor="otherName">Last Name</label>
                <input
                  type="text"
                  name="otherName"
                  id="signup-last-name"
                  placeholder="last name"
                  onChange={handleInputs}
                  value={formData1.otherName}
                  onBlur={handleValidation}
                  style={{ borderColor: "#1e82c5" }}
                />
                <small className="error">{formErrors.otherName}</small>
              </div>
              <div className="signup-phonenumber">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  id="signup-phonenumber"
                  placeholder="Phone number"
                  onChange={handleInputs}
                  value={formData1.phone}
                  onBlur={handleValidation}
                  style={{ borderColor: "#1e82c5" }}
                />
                <small className="error">{formErrors.phone}</small>
              </div>

              <div className="signup-form">
                <p className="successTxt"> {msg}</p>
                <div className="signup-signup__section">
                  <div
                    className="signup-next register-btn"
                    onClick={() => handleSubmit()}
                  >
                    {updateProfileData?.loading ? (
                      <Space size="middle">
                        <Spin size="small" />
                      </Space>
                    ) : (
                      "Update"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
