import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/BCN 1.png";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
  const [open, setOpen] = useState(false);

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-color navbar-light p-0">
        <div className="container">
          <a className="navbar-brand" href="https://www.TradeGPT.site">
            <img src={logo} draggable="false" alt="TradeGPT logo" />
          </a>
          <button
            className="navbar-toggler border-primary nav-bars"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            {/* <i className="fas fa-bars text-primary"></i> */}
            <FontAwesomeIcon
              className="bk-btn"
              icon={faBars}
              // onClick={() => navigate(-1)}
            />
          </button>
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <a href="index">
                  <img src={logo} alt="TradeGPT logo" />
                </a>
              </h5>
              <button
                type="button"
                className="btn-close text-reset text-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-bod justify-content-start">
              <ul className="navbar-nav fw-bold flex-grow-1 pe-3 justify-content-end">
                <li className="nav-item px-3">
                  <Link className="nav-link nav-link2" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link className="nav-link nav-link2" to="/about">
                    About Us
                  </Link>
                </li>
                {/* <li className="nav-item px-3">
                  <a className="nav-link nav-link2" href="/">
                    Telegram
                  </a>
                </li> */}
                <li className="nav-item px-3">
                  <Link className="nav-link nav-link2" to="/howitworks">
                    How it Works
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link className="nav-link nav-link2" to="/terms">
                    Terms
                  </Link>
                </li>
                <li className="nav-item px-3">
                  <Link className="nav-link nav-link2" to="/faq">
                    FAQs
                  </Link>
                </li>
                <li className="nav-item px-3 login">
                  <Link
                    className="nav-link nav-link2 text-white bg-primary px-4"
                    style={{
                      borderRadius: 7,
                      lineHeight: 1,
                      padding: 10,
                    }}
                    to="/signin"
                    reloadDocument
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Nav;
