import React, { useEffect, useState } from "react";
import "./sitesettings.scss";
import Avatar from "../../../images/head.58f0e3ba.svg";
import { Space, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateWallet } from "../../../features/walletSlice";

const SiteSettingsBody = ({
  handleSubmit,
  profileForm,
  setProfileForm,
  updateUserWalletData,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({ ...profileForm, [name]: value });
  };
  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({ ...profileForm, [name]: Number(value) });
  };
  console.log(profileForm);

  const { isLoading } = useSelector((state) => state.updateWalletSlice);

  return (
    <div className="edit-profile-container">
      <div className="epc-detail-box">
        <form action="" onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor=""> Name</label>
            <input
              type="text"
              className="form-input"
              name="name"
              // value={profileForm.name}
              // onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> Email</label>
            <input
              type="email"
              className="form-input"
              name="email"
              // value={profileForm.email}
              // onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> Phone</label>
            <input
              type="text"
              min="0"
              className="form-input"
              name="phone"
              // value={profileForm.combined}
              // onChange={handleNumberChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> Rate</label>
            <input
              type="number"
              min="0"
              className="form-input"
              name="rate"
              // value={profileForm.main}
              // onChange={handleNumberChange}
            />
          </div>
          <button className="btn" type="submit">
            {/* {updateUserWalletData?.loading ? (
              <div className="example">
                <Space size="middle">
                  <Spin size="small" />
                </Space>
              </div>
            ) : (
              "Save"
            )} */}{" "}
            save
          </button>
        </form>
      </div>
    </div>
  );
};

export default SiteSettingsBody;
