import axios from "axios";
import "./App.css";
import Landing from "./components/LandingPage/Landing";
import SignIn from "./components/SignIn/SignIn";
import Signup from "./components/SignUp/Signup";
import Signup2 from "./components/SignUp/Signup2";
import { UserProvider } from "./Context/UserRegister";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserProfile from "./components/Profile/UserProfile";
import EmailConfirm from "./components/EmailConfirm/EmailConfirm";
import Deposit from "./components/Profile/ProfileBody/Deposit";
import Deposit2 from "./components/Profile/ProfileBody/Deposit2";
import Withdraw from "./components/Profile/ProfileBody/Withdraw";
import { DepositProvider } from "./Context/UserDeposit";
import ProtectedRoute from "./components/Profile/ProtectedRoute/ProtectedRoute";
import BigScreen from "./components/Big Screen/BigScreen";
import useWindowSize from "./utils/useWindowSize";
import Spinner from "./TestSpin/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import AdminProfile from "./pages/admin/AdminProfile";
import EditAdminProfile from "./pages/admin/EditProfile";
import Notifications from "./pages/admin/Notifications";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Messages from "./pages/admin/Messages";
import { requestInterceptor, checkJwtExp } from "./utils/axiosFxns";
import DepositReq from "./pages/admin/DepositReq";
import WithdrawalReq from "./pages/admin/WithdrawalReq";
import SiteSettings from "./pages/admin/SiteSettings";
import DepositHistory from "./pages/users/depositHistory";
import WithdrawalHistory from "./pages/users/withdrawalHistory";
import CreateAdmin from "./pages/admin/CreateAdmin";
import UserReferrals from "./pages/users/referrals";
import ChangePassword from "./pages/users/changePassword";
import UpdateProfile from "./pages/users/updateUser";
import Register from "./components/SignUp/register";
import ForgotPassword from "./pages/users/forgotPassword";
import Home from "./pages/Home/home"
import About from "./pages/Home/about"
import Faq from "./pages/Home/faq"
import How from "./pages/Home/how"
import Terms from "./pages/Home/terms";
import NewPassword from "./pages/users/newPassword";

function App() {
  const { width } = useWindowSize();
  // if (width > 500) {
  //   return <BigScreen />;
  // } 
  // axios.defaults.baseURL = "https://bcnetworks.herokuapp.com/";
  // axios.defaults.baseURL =
  //   "https://btnc7rh3vaph4vur2iljwc436m0epqrh.lambda-url.us-east-1.on.aws/";
  axios.defaults.baseURL =
    "https://bcnetwork.onrender.com/";
  // axios.defaults.baseURL =
  //   "https://bcnetworkapi-env.eba-cmxdqbf2.us-east-1.elasticbeanstalk.com/";

  // Add a request interceptor
  // axios.interceptors.request.use(requestInterceptor, function (error) {
  //   // Do something with request error
  //   return Promise.reject(error);
  // });

  // request interceptor to add token to request headers
  // axios.interceptors.request.use(
  //   async (config) => {
  //     const localUser = JSON.parse(localStorage.getItem("persist:root"));
  //     const token = JSON.parse(localUser.user).token;

  //     if (token) {
  //       config.headers = {
  //         authorization: `Bearer ${token}`,
  //       };
  //     }
  //     return config;
  //   },
  //   (error) => Promise.reject(error)
  // );

  // response interceptor intercepting 401 responses, refreshing token and retrying the request
  // axios.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const config = error.config;

  //     if (error.response.status === 401 && !config._retry) {
  //       // we use this flag to avoid retrying indefinitely if
  //       // getting a refresh token fails for any reason
  //       config._retry = true;
  //       // localStorage.setItem("token", await refreshAccessToken());

  //       return axios(config);
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <UserProvider>
          <DepositProvider>
            <Routes>
              <Route path="/" element={<SignIn />  } />
              <Route path="/about" element={<About />  } />
              <Route path="/faq" element={<Faq />  } />
              <Route path="/terms" element={<Terms />  } />
              <Route path="/howitworks" element={<How />  } />
              <Route path="/newpassword" element={<NewPassword />  } />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register-contd" element={<Signup2 />} />
              <Route path="/confirmation" element={<EmailConfirm />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/notifications" element={<Notifications />} />
              {/* <Route path="/admin-dashboard" element={<AdminDashboard />} /> */}
              <Route path="/messages" element={<Messages />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/deposit" element={<Deposit />} />
                <Route path="/deposit-contd" element={<Deposit2 />} />
                <Route path="/withdraw" element={<Withdraw />} />
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                <Route
                  path="/user-profile/:emailId"
                  element={<AdminProfile />}
                />
                <Route
                  path="/edit-profile/:emailId"
                  element={<EditAdminProfile />}
                />
                <Route path="/deposit-requests" element={<DepositReq />} />
                <Route
                  path="/withdrawal-requests"
                  element={<WithdrawalReq />}
                />
                <Route path="/site-settings" element={<SiteSettings />} />
                <Route path="/deposit-history" element={<DepositHistory />} />
                <Route
                  path="/withdrawal-history"
                  element={<WithdrawalHistory />}
                />
                <Route path="/create-admin" element={<CreateAdmin />} />
                <Route path="/referrals" element={<UserReferrals />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/updateprofile" element={<UpdateProfile />} />
              </Route>
            </Routes>
            <ToastContainer limit={2} pauseOnFocusLoss={false} />
          </DepositProvider>
        </UserProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
