export const baseUrl = `https://bcnetwork.onrender.com/`;
// export const baseUrl = `https://bcnetworkapi-env.eba-cmxdqbf2.us-east-1.elasticbeanstalk.com/`;
// export const baseUrl = `https://btnc7rh3vaph4vur2iljwc436m0epqrh.lambda-url.us-east-1.on.aws/`;
// export const baseUrl = `https://bcnetworks.herokuapp.com/`;
export const registerUrl = `${baseUrl}onboarding/signup`;
export const loginUrl = `${baseUrl}auth/login`;
export const getUser = `${baseUrl}wallet/`;
export const depositUrl = `${baseUrl}deposit/create`
export const withdrawUrl = `${baseUrl}withdrawal/create`
export const allUsersUrl = `${baseUrl}onboarding/users`
export const updateWalletUrl = `${baseUrl}wallet/update`
export const allDepositUrl = `${baseUrl}deposit`
export const allWithdrawalUrl = `${baseUrl}withdrawal`
export const depositHistoryUrl = `${baseUrl}deposit/history/`
export const withdrawalHistoryUrl = `${baseUrl}withdraw/history/`
export const approveDepositUrl = `${baseUrl}deposit/update/status/`
export const createNewAdminUrl = `${baseUrl}onboarding/signup/admin`
export const payRoiUrl = `${baseUrl}wallet/pay/roi`
export const payCommissionUrl = `${baseUrl}wallet/update/commission`
