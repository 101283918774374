import React, { useEffect, useState } from "react";
import "./profileDetails.scss";
import Avatar from "../../../images/head.58f0e3ba.svg";
import { Space, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateWallet } from "../../../features/walletSlice";

const EditProfileDetails = ({
  handleSubmit,
  profileForm,
  setProfileForm,
  updateUserWalletData,
  msg,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({ ...profileForm, [name]: value });
  };
  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({ ...profileForm, [name]: value });
  };
  console.log(profileForm);

  const { isLoading } = useSelector((state) => state.updateWalletSlice);

  return (
    <div className="edit-profile-container">
      <div className="epc-detail-box">
        <div className="img-box">
          <img src={Avatar} alt="" />
        </div>
        <form action="" onSubmit={handleSubmit}>
          {/* <div className="input-group">
            <label htmlFor=""> Name</label>
            <input
              type="text"
              className="form-input"
              name="name"
              value={profileForm.name}
              onChange={handleChange}
            />
          </div> */}
          <div className="input-group">
            <label htmlFor=""> Email</label>
            <input
              type="email"
              className="form-input"
              name="email"
              value={profileForm.email}
              onChange={handleChange}
              readOnly
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> Commission Account</label>
            <input
              type="number"
              // min="0"
              className="form-input"
              name="commission"
              value={profileForm.commission}
              onChange={handleNumberChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> Main Account</label>
            <input
              type="number"
              // min="0"
              className="form-input"
              name="main"
              value={profileForm.main}
              onChange={handleNumberChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor=""> ROI Account</label>
            <input
              type="number"
              // min="0"
              className="form-input"
              name="roi"
              value={profileForm.roi}
              onChange={handleNumberChange}
            />
          </div>
          <p className="successTxt"> {msg} </p>
          <button className="btn-btn" type="submit">
            {updateUserWalletData?.loading ? (
              <Space size="middle">
                <Spin size="small" />
              </Space>
            ) : (
              "Save"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfileDetails;
