import useAxios from "axios-hooks";
// const axios = Axios.create({
//   baseURL: `https://bcnetworks.herokuapp.com/`,
// });

// configure({ axios });

const useAdmin = () => {
  //
  //GET Users
  const [{ ...getUsersData }, getUsers] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetUsers = async () => {
    try {
      await getUsers({
        url: `/onboarding/users`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //GET Single User
  const [{ ...singleUserData }, getSingleUserData] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetSingleUser = async (email) => {
    try {
      await getSingleUserData({
        url: `/onboarding/user_single/${email}`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //Delete User
  const [{ ...deleteUserData }, deleteUser] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser({
        url: `/onboarding/user/delete/${id}`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const handleCreateService = async ({ data, file }) => {
  //   setLoading(true);
  //   const newUploaded = [];
  //   // const upload = async () => {
  //   //   const formData = new FormData();
  //   //   formData.append("files", file);
  //   //   return (await handleUploads(formData)).data?.results[0].id;
  //   // };
  //   const formData = new FormData();
  //   formData.append("files", file);
  //   const uploadedData = await handleUploads(formData);

  //   newUploaded.push(uploadedData.data?.results[0]?.id);
  //   await createService({
  //     url: "/services/",
  //     data: {
  //       ...data,
  //       // ...(file && { gallary: await upload() }),
  //       ...(newUploaded?.length && {
  //         gallary: newUploaded,
  //       }),
  //     },
  //   });
  //   // console.log("upload file", upload())
  //   setLoading(false);
  //   console.log("handle create service", createServiceData);
  //   if (createServiceData?.response?.response?.status === 400) {
  //     // history.push(`/explore`);
  //     showToast("error", "An error occured");
  //     console.log(" showToast error ");
  //   }
  // };

  // useEffect(() => {
  //   if (createServiceData?.response?.response?.status === 400) {
  //     // history.push(`/explore`);
  //     showToast("error", "An error occured");
  //   }
  // }, [createServiceData?.response]);

  //
  //
  //
  //RETRIEVE A SERVICE
  const [{ ...singleServiceData }, getSingleService] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  //
  //
  //UPDATE A SERVICE
  const [{ ...updateUserWalletData }, updateUserWallet] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateUserWallet = async (data) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await updateUserWallet({
        url: `/wallet/update`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //CREATE A DEPOSIT
  const [{ ...createDepositData }, createDeposit] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateDeposit = async (data) => {
    try {
      await createDeposit({
        url: `/deposit/create`,
        data: {
          data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //CREATE ROI
  const [{ ...createRoiData }, createRoi] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateRoi = async (data) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await createRoi({
        url: `/wallet/pay/roi`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //CREATE COMMISSION
  const [{ ...createCommissionData }, createCommission] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateCommission = async (data) => {
    try {
      await createCommission({
        url: `/wallet/pay/commission`,
        data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //UPDATE USER DEPOSIT
  const [{ ...updateUserDepositData }, updateUserDeposit] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateUserDeposit = async (ref, email) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await updateUserDeposit({
        url: `/deposit/update/status/${ref}`,
        data: {
          email,
          ref,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //LIST WITHDRAWALS
  const [{ ...getwithdrawalsData }, getWithdrawals] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetWithdrawals = async () => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await getWithdrawals({
        url: `/withdrawal`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //UPDATE USER WITHDRAWAL
  const [{ ...updateUserWithdrawalData }, updateUserWithdrawal] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateUserWithdrawal = async (ref) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await updateUserWithdrawal({
        url: `/withdrawal/update/status/${ref}`,
        data: {
          ref,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //DECLINE USER WITHDRAWAL
  const [{ ...declineUserWithdrawalData }, declineUserWithdrawal] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleDeclineUserWithdrawal = async (ref) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await declineUserWithdrawal({
        url: `/withdrawal/update/status/decline/${ref}`,
        data: {
          ref,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //CREATE ADMIN
  const [{ ...createAdminData }, createAdmin] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateAdmin = async (data) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await createAdmin({
        url: `/onboarding/signup/admin`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //
  //CHANGE PASSWORD
  const [{ ...changePasswordData }, changePassword] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleChangePassword = async (data) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await changePassword({
        url: `/onboarding/user/update/password`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  //
  //
  //
  //CHANGE PASSWORD2
  const [{ ...changePassword2Data }, changePassword2] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleChangePassword2 = async (data, token) => {
    try {
      // const localUser = JSON.parse(localStorage.getItem("persist:root"));
      // const token = JSON.parse(localUser.user).token;
      await changePassword2({
        url: `/onboarding/user/update/password`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //
  //UPDATE PROFILE
  const [{ ...updateProfileData }, updateProfile] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateProfile = async (data) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await updateProfile({
        url: `/onboarding/user/update/`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //forgot Password
  const [{ ...forgortPasswordData }, forgotPassword] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleForgotPassword = async (data) => {
    try {
      // const localUser = JSON.parse(localStorage.getItem("persist:root"));
      // const token = JSON.parse(localUser.user).token;
      await forgotPassword({
        url: `/onboarding/password/reset`,
        data,
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //reset Password token
  const [{ ...forgortPasswordTokenData }, forgotPasswordToken] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleForgotPasswordToken = async (data) => {
    try {
      await forgotPasswordToken({
        url: `/onboarding/forgot`,
        data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //GET Users
  const [{ ...getTotalUsersData }, getTotalUsers] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleGetTotalUsers = async () => {
    try {
      const localUser = JSON.parse(localStorage.getItem("persist:root"));
      const token = JSON.parse(localUser.user).token;
      await getTotalUsers({
        url: `/wallet/totals/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //REGISTER USER
  const [{ ...registerUserData }, registerUser] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleRegisterUser = async (data) => {
    try {
      await registerUser({
        url: `/onboarding/signup`,
        data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getUsersData,
    handleGetUsers,
    getSingleUserData,
    handleGetSingleUser,
    deleteUserData,
    handleDeleteUser,
    updateUserWalletData,
    handleUpdateUserWallet,
    createDepositData,
    handleCreateDeposit,
    createRoi,
    handleCreateRoi,
    createRoiData,
    handleCreateCommission,
    createCommissionData,
    handleUpdateUserDeposit,
    updateUserDepositData,
    getwithdrawalsData,
    handleGetWithdrawals,
    updateUserWithdrawalData,
    handleUpdateUserWithdrawal,
    createAdminData,
    handleCreateAdmin,
    changePasswordData,
    handleChangePassword,
    updateProfileData,
    handleUpdateProfile,
    forgortPasswordData,
    handleForgotPassword,
    getTotalUsersData,
    handleGetTotalUsers,
    registerUserData,
    handleRegisterUser,
    forgortPasswordTokenData,
    handleForgotPasswordToken,
    handleDeclineUserWithdrawal,
    declineUserWithdrawalData,
    handleChangePassword2,
    changePassword2Data
  };
};

export default useAdmin;
