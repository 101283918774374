import Footer from "./footer";
import Nav from "./nav";
import "./style.css";
import about from "../../assets/images/about.jpg";
import withnew from "../../images/bread.jpg";
import withnew1 from "../../assets/images/withnew.jpg";
import withnew2 from "../../images/s1.png";

const How = () => {
  return (
    <div>
      <Nav />
      <div className="container">
        <div className="text-center mt-4 fw-bold section-header">
          <h2
            className=" josefin"
            style={{
              backgroundColor: "#0b5ba1",
              color: "#fff",
              padding: 10,
              display: "inline",
            }}
          >
            How it works
          </h2>
        </div>
        <div className="py-2 container">
          {/* <div className="col-lg-4">
            <img src={about} alt="" width="100%" />
          </div> */}
          <div className="containrs mt-3">
            <p className="text-center fw-bold">
              There are three unique ways to earn in TradeGPT
            </p>
            <div className="row align-items-center ">
              <div className="col-lg-6">
                <img src={withnew} alt="withdraw" width="100%" />
              </div>
              <div className="col-lg-6">
                <p className="pt-3 fw-bold mb-1 text-center">
                  1. From your investment.
                </p>
                <p className="text-center">
                  You earn up to 15% of your invested capital monthly in your
                  roi account. Minimum investment of $50 required.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img src={withnew1} alt="withdraw" width="100%" />
              </div>
              <div className="col-lg-6">
                <p className="pt-3 fw-bold mb-1 text-center">
                  2. From your referrals.
                </p>
                <p className="text-center">
                  Refer a friend and earn unto 5% roi of their equity monthly.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img src={withnew2} alt="withdraw" width="100%" />
              </div>
              <div className="col-lg-6">
                <p className="pt-3 fw-bold mb-1 text-center">
                  3. From referral deposit bonus.
                </p>
                <p className="text-center">
                  You earn a referral deposit bonus of 10% each time your
                  referral makes a deposit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default How;
