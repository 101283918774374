import React, { useEffect, useState } from "react";
import "./transactions.scss";
import Avatar from "../../../images/profilePic.png";
import { Link } from "react-router-dom";
import { Space, Spin, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchAllDeposit } from "../../../features/allDepositSlice";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { adminApproveDeposit } from "../../../features/Deposits/approveDepositsSlice";
import useAdmin from "../../../hooks/useAdmin";

const DepositReqList = ({ title }) => {
  const testArr = [1, 2, 3];
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  const { isError, message, allDeposit, isLoading } = useSelector(
    (state) => state.allDepositSlice
  );
  const { approveDeposit } = useSelector(
    (state) => state.adminApproveDepositSlice
  );

  const { handleUpdateUserDeposit, updateUserDepositData } = useAdmin();
  const localUser = JSON.parse(localStorage.getItem("persist:root"));
  const token = JSON.parse(localUser.user).token;
  console.log(localUser);
  console.log(approveDeposit);
  useEffect(() => {
    dispatch(fetchAllDeposit());
    // if (isError) {
    //   toast.error(`Error! ${message}`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
  }, []);
  // useEffect(() => {
  //   dispatch(fetchAllDeposit());
  //   // if (isError) {
  //   //   toast.error(`Error! ${message}`, {
  //   //     position: toast.POSITION.TOP_CENTER,
  //   //   });
  //   // }
  // }, [updateUserDepositData?.response]);
  console.log(allDeposit);
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  useEffect(() => {
    if (updateUserDepositData?.response?.status === 201) {
      dispatch(fetchAllDeposit());
    }
  }, [updateUserDepositData?.response]);
  console.log(updateUserDepositData);

  const myDeposits = allDeposit?.data?.map((data, idx) => {
    return data
  });
  console.log(myDeposits)


  return (
    <div className="drl-container">
      {isLoading ? (
        <div
          className="example"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Image</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {myDeposits?.reverse()?.map((data, idx) => (
              <tr>
                <td>{idx + 1}</td>
                <td>{data.email}</td>
                <td>${data.amount}</td>
                <td>
                  <PhotoProvider>
                    <PhotoView src={data.poe}>
                      <img className="table-img" src={data.poe} alt="" />
                    </PhotoView>
                  </PhotoProvider>{" "}
                </td>
                <td>{data.status}</td>
                <td>
                  <button
                    className="approve-btn"
                    style={{
                      background: data.status === "approved" && "#f7f7f7",
                      color: data.status === "approved" && "#000",
                    }}
                    disabled={data.status === "approved"}
                    onClick={() =>
                      // dispatch(adminApproveDeposit(data.ref, data.email))
                      handleUpdateUserDeposit(data.ref, data.email, {
                        email: data.email,
                        ref: data.ref,
                      })
                    }
                  >
                    {data.status === "approved" ? "---" : "Approve"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* <Table dataSource={dataSource} columns={columns} />; */}
    </div>
  );
};

export default DepositReqList;
