import React, { useEffect, useState } from "react";
import "./usersTransactions.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import DepositHistoryList from "../../components/Users/DepositHistoryList";

const DepositHistory = () => {
  return (
    <div className="user-transactions-container">
      <AdminProfileNav title={"Deposit History"} visible={false} />
      <main>
        <DepositHistoryList />
      </main>
    </div>
  );
};

export default DepositHistory;
