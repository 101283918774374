import React, { useEffect, useState } from "react";
import "./usersTransactions.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import WithdrawalHistoryList from "../../components/Users/WithdrawalHistoryList";

const WithdrawalHistory = () => {
  return (
    <div className="user-transactions-container">
      <AdminProfileNav title={"Withdrawal History"} visible={false} />
      <main>
        <WithdrawalHistoryList />
      </main>
    </div>
  );
};

export default WithdrawalHistory;
