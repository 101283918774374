import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createNewAdminUrl } from "../../services/urls";
import { registerAdmin } from "../../services/apiServices";

// check local storage for saved user
//const user = JSON.parse(localStorage.getItem("bcn_user"));

export const registerNewAdmin = createAsyncThunk(
  createNewAdminUrl,
  async (admin, thunkAPI) => {
    try {
      const token = thunkAPI.getState().userReducer.user.token;
      return await registerAdmin(admin, token).data;
    } catch (error) {
      const message = error.response.data.message;
      console.log(message)
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  user: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  isLoggedIn: false,
  token: "",
};

export const newAdminSlice = createSlice({
  name: "newadmin",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
    expiredToken: (state) => {
      state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerNewAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerNewAdmin.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.user = action.payload;
      state.isSuccess = true;
      state.isError = false;
      state.token = action.payload;
    });
    builder.addCase(registerNewAdmin.rejected, (state, action) => {
      console.log(action.payload);
      state.isError = true;
      state.isLoading = false;
      state.message = action.payload;
      state.user = null;
    });
  },
});

export const { reset, expiredToken } = newAdminSlice.actions;

export default newAdminSlice.reducer;
