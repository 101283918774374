import React, { useState, useEffect } from "react";
import "./DepositAndWithdraw.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { conversionRate } from "../../../utils/formatNumber";
import { useSelector, useDispatch } from "react-redux";
import { Space, Spin } from "antd";
import "antd/es/spin/style/css";
import { toast } from "react-toastify";
import { handleImageUpload } from "../../../hooks/imageCompression";
import { resetDeposit, makeDeposit } from "../../../features/depositSlice";
import CountdownTimer from "react-component-countdown-timer";
import QR from "../../../images/lunoqr.jpg";
import Modal from "react-responsive-modal";
import success from "../../../images/verify-success.png";

export default function Deposit() {
  const [copied, setCopied] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [imgFile, setImgFile] = useState(undefined);
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const dispatch = useDispatch();
  const { amountToDeposit, isLoading, isSuccess, isError, message } =
    useSelector((state) => state.depositSlice);
  const { email } = useSelector((state) => state.userReducer.user);
  let navigate = useNavigate();
  //context

  //handle copied show state
  const showCopied = () => {
    navigator.clipboard.writeText("3F2Dh6j4eXVj5h9tfaTX4GofYZGYzdDQD4");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleDeposit = () => {
    console.log(error);
    if (error === null) {
      const formData = new FormData();
      formData.append("image", imgFile);
      formData.append("email", email);
      formData.append("amount", amountToDeposit);
      formData.append("rate", 0.5);
      console.table(formData);
      dispatch(makeDeposit(formData));
    }
  };

  useEffect(() => {
    if (parseInt(amountToDeposit) < Number(500) || !parseInt(amountToDeposit)) {
      setError("500 USD minimum deposit");
    }
  }, [amountToDeposit]);
  useEffect(() => {
    const controller = new AbortController();
    if (isError) {
      toast.error(`Error! ${message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      //dispatch(resetDeposit());
    }
    if (isSuccess) {
      //   toast.success(`Success! Deposit Successful`, {
      //     position: toast.POSITION.TOP_LEFT,
      //   });
      onOpenModal();
      //   navigate("/deposit");

      dispatch(resetDeposit());
    }
    return () => controller.abort();
  }, [dispatch, isError, isSuccess, message]);
  console.table(
    isError,
    isSuccess,
    isLoading,
    message,
    " error sucess loading msg"
  );

  // let btcValue = parseFloat(conversionRate()) * parseFloat(amountToDeposit);
  let btcValue = parseFloat(amountToDeposit) / 30000;

  console.log(imgFile);
  console.log(selectedImage);
  return (
    <div className="deposit-container">
      <div className="deposit-row">
        <div style={{ height: "100%" }} className="deposit-form">
          <div className="di-card">
            <span>
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => navigate(-1)}
              />
            </span>
            <div className="deposit-header">
              <h3 style={{ color: "#2666CF" }}>Complete Deposit.</h3>
              <CountdownTimer
                count={600}
                //   showTitle
                border
                size={25}
                hideDay
                hideHours
                color={"red"}
                //   noPoints
              />{" "}
            </div>
            <div className="deposit-rate">
              <p>
                Amount: <span>{amountToDeposit} USD</span>
              </p>
              <p>
                Fixed Exchange Rate: <span> 30000 USD</span>
              </p>
              <p>
                Billing Amount: <span> {Number(btcValue).toFixed(5)} BTC </span>
              </p>
              <div className="info-deposit">
                <p>
                  1. Please complete payment within the remaining time shown
                  above, otherwise create a new deposit request by filling the
                  deposit form again and follow same procedure
                </p>
                <p>
                  2. Please upload a copy/screenshot of the transfer for
                  possible further verification
                </p>
                <p>
                  3. Make sure you fill the deposit form and send money to the
                  displayed wallet ID
                </p>
                <p>
                  4. Please send exact amount in same currency as billing
                  currency
                </p>
              </div>
            </div>
            <div className="qr-code-box">
              <img src={QR} alt="qr-code" className="qr-code" />
            </div>

            <div style={{ marginBottom: 10, minHeight: 70 }}>
              <div
                className="deposit-form-item deposit-form-item2"
                id="deposit-form-item-1"
              >
                <input
                  type="text"
                  readOnly
                  className="wallet-address"
                  value="3F2Dh6j4eXVj5h9tfaTX4GofYZGYzdDQD4"
                />
                <FontAwesomeIcon
                  icon={faCopy}
                  className="copy"
                  onClick={showCopied}
                />
              </div>
              {copied && <span className="tooltip">Copied</span>}
            </div>

            <div className="deposit-form-item">
              <label htmlFor="deposit_image">
                Proof of Payment Image{" "}
                <span className="required">required</span>
              </label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(event) => {
                  //dispatch(setImage(event.target.files[0]));
                  setImgFile(event.target.files[0]);
                  console.log(event.target.files[0]);
                  const reader = new FileReader();
                  reader.readAsDataURL(event.target.files[0]);
                  reader.onloadend = () => {
                    setSelectedImage(reader.result);
                    console.log(selectedImage, " selected images");
                  };
                  //setImgFile(event.target.files[0]);
                }}
              />
              {selectedImage && (
                <div className="img-box-2">
                  <img
                    alt="not found"
                    width={"250px"}
                    src={URL.createObjectURL(imgFile)}
                    className="img-box-img"
                  />
                  <br />
                  <button onClick={() => setSelectedImage(undefined)}>
                    Remove
                  </button>
                </div>
              )}
            </div>
            {/* errr display */}
            <small style={{ color: "#FF1818" }}>{error}</small>
            <div
              className="deposit-next submit"
              onClick={() => handleDeposit()}
            >
              {isLoading ? (
                <div className="example">
                  <Space size="middle">
                    <Spin size="small" />
                  </Space>
                </div>
              ) : (
                "Deposit"
              )}
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal open={open} onClose={onCloseModal} center closeIcon>
          <div className="modal-body-content">
            <h3>Transaction Successful</h3>
            <img src={success} alt="" />
            <p>
              your payment is being processed <br></br> and your wallet will be
              credited shortly
            </p>
            <button onClick={() => navigate("/deposit")}> Continue</button>
          </div>
        </Modal>
      )}
    </div>
  );
}
