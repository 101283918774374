import React, { useEffect, useState, useContext } from "react";
import "./usersTransactions.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import WithdrawalHistoryList from "../../components/Users/WithdrawalHistoryList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Space, Spin } from "antd";
// import { toast } from "react-toastify";
import "antd/es/spin/style/css";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../Context/UserRegister";
import useAdmin from "../../hooks/useAdmin";
import axios from "axios";
import { baseUrl } from "../../services/urls";

const NewPassword = () => {
  let navigate = useNavigate();
  const [erroMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { handleChangePassword2, changePassword2Data } = useAdmin();

  const { email, dataToken } = useContext(UserContext);

  // state for passwod visibilty
  const [passwordVisiblility1, setPasswordVisibility1] = useState(false);
  const [passwordVisiblility2, setPasswordVisibility2] = useState(false);

  const dispatch = useDispatch();



  const [formData1, setFormData1] = useState({
    email: email.email,
    password: "",
    confirm_password: "",
  });
  const handleInputs = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };
  console.log(formData1);

  const clearInputs = () => {
    setFormData1({
      email: "",
      password: "",
      confirm_password: "",
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Password is Required.";
    } else if (!/\d/.test(values.password)) {
      errors.password = "Password Should Contain AtLeast A Number.";
    } else if (!/[a-z]/.test(values.password)) {
      errors.password = "Password Should Contain Lower Case.";
    } else if (!/[A-Z]/.test(values.password)) {
      errors.password = "Password Should Contain Upper Case.";
    } else if (!/[!#=@$%&*)(_-]/.test(values.password)) {
      errors.password = "Password Should Contain A Special Character.";
    } else if (values.password.length < 8) {
      errors.password = "Password Should Contain At Least 8 characters.";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is Required";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Passwords Do Not Match.";
    }
    // check for no errors before allowing to send

    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
    return errors;
  };
  //password toggle visibility
  const togglePassowrdVisibility1 = () => {
    setPasswordVisibility1(passwordVisiblility1 ? false : true);
  };
  const togglePassowrdVisibility2 = () => {
    setPasswordVisibility2(passwordVisiblility2 ? false : true);
  };

  const handleValidation = () => {
    setFormErrors(validate(formData1));
  };

  const handleSubmit = async () => {
    // const localUser = JSON.parse(localStorage.getItem("persist:root"));
    // const token = JSON.parse(localUser.user).token;
    // const newData = {
    //   email: email.email,
    //   password: formData1.password,
    //   comfirmPassword: formData1.confirm_password,
    // };
    handleValidation();
    if (isSubmit) {
      handleChangePassword2(formData1, dataToken.user.token);
    //   let response = await axios({
    //     method: "PATCH",
    //     url: `${baseUrl}onboarding/user/update/password`,
    //     formData1,
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   return response.formData1;
    }
  };
  console.log(isSubmit);
  console.log(changePassword2Data);
  console.log(dataToken.user.token);

  useEffect(() => {
    if (changePassword2Data?.response?.status === 201) {
      setSuccessMsg("Success! Password updated");
      setTimeout(() => {
        setSuccessMsg("");
        navigate("/signin");
      }, 2000);
    }
  }, [changePassword2Data?.response]);

  useEffect(() => {
    if (changePassword2Data?.error?.response?.data?.status === "error") {
      setErrorMsg("An error occured!");
      setTimeout(() => {
        setErrorMsg("");
      }, 2000);
    }
  }, [changePassword2Data?.error]);

  return (
    <div className="user-transactions-container">
      <div className="change-password-nav">
        <span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} />
        </span>
        <h3> Change Password</h3>
      </div>

      <main>
        <div className="signup-password">
          <div className="signup-form">
            <label htmlFor="password">New Password</label>
            <input
              type={passwordVisiblility1 ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter password "
              value={formData1.password}
              onChange={handleInputs}
              style={{ borderColor: "#1e82c5" }}
            />

            <FontAwesomeIcon
              icon={faEye}
              className="reveal2"
              onClick={() => togglePassowrdVisibility1()}
            />
          </div>
          <small className="error">{formErrors.password}</small>
          <div className="signup-confirm-password signup-form">
            <label htmlFor="confirm_password">Confirm New Password</label>
            <input
              type={passwordVisiblility2 ? "text" : "password"}
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm password"
              value={formData1.confirm_password}
              onChange={handleInputs}
              style={{ borderColor: "#1e82c5" }}
            />
            <FontAwesomeIcon
              icon={faEye}
              className="reveal2"
              onClick={() => togglePassowrdVisibility2()}
            />
          </div>

          <small className="error">{formErrors.confirm_password}</small>
          <div className="signup-signup__section">
            <p className="err-txt">{erroMsg}</p>
            <p className="success-txt">{successMsg}</p>

            <div
              className="signup-next register-btn"
              onClick={() => handleSubmit()}
            >
              {changePassword2Data?.loading ? (
                <Space size="middle">
                  <Spin size="small" />
                </Space>
              ) : (
                "update"
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewPassword;
