import React, { useEffect, useState } from "react";
import "./admin.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import EditProfileDetails from "../../components/Admin/Profile/editProfileDetails";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchInvestor } from "../../features/investorSlice";
import { updateWallet } from "../../features/walletSlice";
import { Space, Spin } from "antd";
import useAdmin from "../../hooks/useAdmin";
import useAxios from "axios-hooks";

const EditAdminProfile = () => {
  const { emailId } = useParams();
  const location = useLocation();
  console.log(emailId);
  console.log(location);
  const { handleUpdateUserWallet, updateUserWalletData, getUsersData } =
    useAdmin();

  const [msg, setMsg] = useState("");

  const { user } = useSelector((state) => state.userReducer);
  const { isError, message, combined, roi, commission, main, isLoading } =
    useSelector((state) => state.investorSlice);
  const { allUsers } =
    useSelector((state) => state.updateWalletSlice);

  const dispatch = useDispatch();

  const initialFormState = {
    email: emailId,
    commission: commission,
    main: main,
    roi: roi,
  };

  const [profileForm, setProfileForm] = useState(initialFormState);
  console.log(profileForm);
  useEffect(() => {
    dispatch(fetchInvestor(emailId));
    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, [isError, emailId, dispatch, message]);

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(updateWallet(profileForm));
    handleUpdateUserWallet(profileForm);
  };

  // console.log(handleUpdateUserWallet)
  console.log("updateUserWalletData", updateUserWalletData);
  
  useEffect(() => {
    if (updateUserWalletData?.response?.status === 201) {
      setMsg("Successfully updated");
      setTimeout(() => {
        setMsg("");
      }, 2000);
    }
  }, [updateUserWalletData?.response]);
  // const [{ ...getUsersData }, getUsers] = useAxios(
  //   {
  //     method: "GET",
  //   },
  //   {
  //     manual: true,
  //   }
  // );

  // const handleGetUsers = async () => {
  //   try {
  //     await getUsers({
  //       url: `/wallet`,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  //   useEffect(() => {
  //     handleGetUsers()
  //   }, []);

  // console.log(getUsersData)
  // useEffect(() => {
  //   const controller = new AbortController();
  //   if (isError) {
  //     if (message === "Auth Failed") {
  //       toast.error(`Error! Invalid username or password`, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       dispatch(reset());
  //     } else {
  //       console.log(message, " message");
  //       toast.error(`Error! ${message}`, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       dispatch(reset());
  //     }
  //   }
  //   if (isSuccess) {
  //     toast.success(`Success! Logged In Successfully`, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     dispatch(reset());
  //     // navigate(from, { replace: true });
  //     if (message.role === "admin") {
  //       navigate("/admin-dashboard", { replace: true });
  //     } else {
  //       navigate("/profile");
  //     }
  //   }
  //   return () => controller.abort();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isError, isSuccess, isLoading, message, navigate, user]);
  // //console.log(isError, isSuccess, isLoading, message, navigate, user, " chek");

  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Edit Profile"} visible={false} />
      <main>
        {isLoading ? (
          <div
            className="example"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <EditProfileDetails
            profileForm={profileForm}
            setProfileForm={setProfileForm}
            // isLoading={isLoading}
            handleSubmit={handleSubmit}
            updateUserWalletData={updateUserWalletData}
            msg={msg}
          />
        )}
      </main>
    </div>
  );
};

export default EditAdminProfile;
