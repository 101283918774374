import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../../Context/UserRegister";
import "../../SignUp/Signup.css";
import "./createadmin.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye } from "@fortawesome/free-solid-svg-icons";

import { Outlet, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Space, Spin } from "antd";
// import { toast } from "react-toastify";
import "antd/es/spin/style/css";
//we used context just for user form info, we would be upgrading to redux soon
import { registerNewAdmin } from "../../../features/NewAdmin/NewAdminSlice";
import useAdmin from "../../../hooks/useAdmin";

export default function CreateAdminBody() {
  //state for local component to track error---- validation
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  // state for passwod visibilty
  const [passwordVisiblility1, setPasswordVisibility1] = useState(false);
  const [passwordVisiblility2, setPasswordVisibility2] = useState(false);

  const { handleCreateAdmin, createAdminData } = useAdmin();

  //   useEffect(() => {
  //     if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     }
  //   }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let phoneRegex =
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    if (!values.firstName) {
      errors.firstName = "First Name is Required";
    } else if (values.firstName.length < 3) {
      errors.firstName = "First Name should be more than 3";
    }

    if (!values.otherName) {
      errors.otherName = "Last Name is Required";
    } else if (values.otherName.length < 3) {
      errors.otherName = "Last Name should be more than 3";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is Required";
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = "Invalid Phone Number";
    }

    // if (!values.userType) {
    //   errors.userType = "User should either be Admin or User";
    // }

    if (!values.email) {
      errors.email = "Email is Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email Format!";
    }

    if (!values.password) {
      errors.password = "Password is Required.";
    } else if (!/\d/.test(values.password)) {
      errors.password = "Password Should Contain AtLeast A Number.";
    } else if (!/[a-z]/.test(values.password)) {
      errors.password = "Password Should Contain Lower Case.";
    } else if (!/[A-Z]/.test(values.password)) {
      errors.password = "Password Should Contain Upper Case.";
    } else if (!/[!#=@$%&*)(_-]/.test(values.password)) {
      errors.password = "Password Should Contain A Special Character.";
    } else if (values.password.length < 8) {
      errors.password = "Password Should Contain At Least 8 characters.";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is Required";
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Passwords Do Not Match.";
    }

    // check for no errors before allowing to send

    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }
    return errors;
  };

  ////////////////////////////////

  //import context state for user's input
  const { formData1, handleInputs, clearInputs } = useContext(UserContext);

  const { isError, isLoading, isSuccess, message } = useSelector(
    (state) => state.newAdminSlice
  );

  console.log(formData1);
  console.log(isSubmit);
  const handleSubmit = (e) => {
    const newData = {
      firstName: formData1.firstName,
      otherName: formData1.otherName,
      email: formData1.email,
      password: formData1.password,
      comfirmPassword: formData1.confirm_password,
      phone: formData1.phone,
    };
    handleValidation();
    if (isSubmit) {
      // 	resStatus === "success" && clearInputs();
      //   dispatch(registerNewAdmin(newData));
      handleCreateAdmin(newData);
    }
  };

  //validation
  const handleValidation = () => {
    setFormErrors(validate(formData1));
  };

  // console.log(message, " Is message");
  //console.log(isError, " Is Loading");

  useEffect(() => {
    if (isError) {
      //   toast.error(`Error! ${message}`, {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
    }
    if (isSuccess) {
      //   toast.success(`Success! New Admin created successfully`, {
      //     position: toast.POSITION.TOP_LEFT,
      //   });
      clearInputs();
      //   navigate("/admin-dashboard");
    }
  }, [isError, message]);

  //password toggle visibility
  const togglePassowrdVisibility1 = () => {
    setPasswordVisibility1(passwordVisiblility1 ? false : true);
  };
  const togglePassowrdVisibility2 = () => {
    setPasswordVisibility2(passwordVisiblility2 ? false : true);
  };

  useEffect(() => {
    if (createAdminData?.response?.status === 201) {
      setMsg("Successfully created Admin");
      setTimeout(() => {
        setMsg("");
      }, 2000);
    } else {
      setMsg(
        `An error occured 
        `
      );
      setTimeout(() => {
        setMsg("");
      }, 2000);
    }
  }, [createAdminData?.response]);
  console.log(createAdminData);

  return (
    <div className="signup-container2">
      <div className="signup-row">
        <form onSubmit={handleSubmit}>
          <div className="signup-form">
            <div className="signup-name">
              <label htmlFor="firstName">First Name</label>
              <input
                value={formData1.firstName}
                type="text"
                name="firstName"
                id="signup-first-name"
                placeholder="first name"
                onChange={handleInputs}
                onBlur={handleValidation}
              />
              <small className="error">{formErrors.firstName}</small>
            </div>
            <div className="signup-name">
              <label htmlFor="otherName">Last Name</label>
              <input
                type="text"
                name="otherName"
                id="signup-last-name"
                placeholder="last name"
                onChange={handleInputs}
                value={formData1.otherName}
                onBlur={handleValidation}
              />
              <small className="error">{formErrors.otherName}</small>
            </div>
            <div className="signup-phonenumber">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="number"
                name="phone"
                id="signup-phonenumber"
                placeholder="Phone number"
                onChange={handleInputs}
                value={formData1.phone}
                onBlur={handleValidation}
              />
              <small className="error">{formErrors.phone}</small>
            </div>

            <div className="signup-form">
              <div className="signup-email">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  id="signup-email"
                  placeholder="ac1384726@gmail.com"
                  value={formData1.email}
                  onChange={handleInputs}
                />
                <small className="error">{formErrors.email}</small>
              </div>
              <div className="signup-password">
                <label htmlFor="password">Password</label>
                <input
                  type={passwordVisiblility1 ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter password "
                  value={formData1.password}
                  onChange={handleInputs}
                />

                <FontAwesomeIcon
                  icon={faEye}
                  className="reveal"
                  onClick={() => togglePassowrdVisibility1()}
                />
              </div>
              <small className="error">{formErrors.password}</small>
              <div className="signup-confirm-password">
                <label htmlFor="confirm_password">Confirm Password</label>
                <input
                  type={passwordVisiblility2 ? "text" : "password"}
                  name="confirm_password"
                  id="confirm_password"
                  placeholder="Confirm password"
                  value={formData1.confirm_password}
                  onChange={handleInputs}
                />
                <FontAwesomeIcon
                  icon={faEye}
                  className="reveal"
                  onClick={() => togglePassowrdVisibility2()}
                />
              </div>

              <small className="error">{formErrors.confirm_password}</small>
              <p className="successTxt"> {msg}</p>
              <div className="signup-signup__section">
                <div
                  className="signup-next register-btn"
                  onClick={() => handleSubmit()}
                >
                  {createAdminData?.loading ? (
                    <Space size="middle">
                      <Spin size="small" />
                    </Space>
                  ) : (
                    "Register"
                  )}
                </div>
              </div>
            </div>
            {/* 
          <div className="signup-next-container">
            <Link
              to="/register-contd"
              style={{
                color: "white",
                // pointerEvents: isSubmit ? "" : "none",
              }}
            >
              <div className="signup-next" onClick={handleValidation}>
                Next
              </div>
            </Link>{" "}
            <div
              style={{
                color: "white",
                // pointerEvents: error.noError ? "" : "none",
              }}
              className="signup-next"
              onClick={handleValidation}
            >
              Next
            </div>
          </div> */}
          </div>
        </form>
      </div>
      <Outlet />
    </div>
  );
}
