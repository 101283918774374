import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Spin } from "antd";
import { login, reset } from "../../features/userSlice";
// import { toast } from "react-toastify";
import "antd/es/spin/style/css";
// import axios from "axios";
import "../../components/SignIn/SignIn.css";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import useAdmin from "../../hooks/useAdmin";
import UserContext from "../../Context/UserRegister";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const { email, setEmail, dataToken, setDataToken } = useContext(UserContext);

  const [code, setCode] = useState({
    code: "",
  });
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [erroMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [step, setStep] = useState(1);
  const [hideBack, setHideBack] = useState(false);

  const handleEmail = (e) => {
    const { name, value } = e.target;
    // setEmail(e.target.value);
    setEmail({ ...email, [name]: value });
  };
  const handleOtp = (e) => {
    const { name, value } = e.target;
    setCode({ ...code, [name]: value });
  };

  console.log(email);

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const {
    handleForgotPassword,
    forgortPasswordData,
    forgortPasswordTokenData,
    handleForgotPasswordToken,
  } = useAdmin();
  //handle submit
  const handleSubmit = () => {
    const newData = { email };
    if (isSubmit) {
      handleForgotPassword(email);
    }
    console.log(newData);
  };
  const handleSubmit2 = () => {
    const newData = { email, code };
    if (isSubmit) {
      handleForgotPasswordToken({ email: email.email, code: code.code });
    }
    console.log(newData);
  };

  useEffect(() => {
    let values = {
      email,
    };
    setFormErrors(validate(values));
  }, [email]);

  useEffect(() => {
    if (forgortPasswordData?.data?.status === "success") {
      setMsg("Success! Reset Password has been sent to your email");
      setTimeout(() => {
        setMsg("");
        nextStep();
      }, 2000);
    }
  }, [forgortPasswordData?.data]);

  useEffect(() => {
    if (forgortPasswordData?.error?.response?.data?.status === "error") {
      setErrorMsg("Account not Found");
      setTimeout(() => {
        setErrorMsg("");
      }, 2000);
    }
  }, [forgortPasswordData?.error]);

  console.log(forgortPasswordData);
  useEffect(() => {
    if (forgortPasswordTokenData?.data?.status === "success") {
      // setMsg("Success! Reset Password has been sent to your email");
      setHideBack(true);
      setDataToken({
        user: {
          token: forgortPasswordTokenData?.data?.data?.token,
        },
      });
      // localStorage.setItem("persist:root", JSON.stringify(dataToken));
      setTimeout(() => {
        navigate("/newpassword");
        // console.log("first")
      }, 3000);
    }
  }, [forgortPasswordTokenData?.data]);

  useEffect(() => {
    localStorage.setItem("persist:root", JSON.stringify(dataToken));
  }, [dataToken]);

  console.log(forgortPasswordTokenData);
  console.log(dataToken);
  //validation
  const validate = (values) => {
    const errors = { email: "" };
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!values.email.email) {
      errors.email = "Email is Required";
    } else if (!emailRegex.test(values.email.email)) {
      errors.email = "Invalid Email Format!";
    }

    if (errors.email.length === 0) {
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
    }

    return errors;
  };

  const PageDisplay = () => {
    switch (step) {
      case 1:
        return (
          <div className="signin-form" style={{ marginTop: 25 }}>
            <div className="signin-email">
              <label htmlFor="signin-email">Email</label>
              <input
                type="text"
                name="email"
                id="signin-email"
                placeholder="user@email.com"
                value={email.email}
                onChange={handleEmail}
                style={{ borderColor: "#1e82c5" }}
              />
            </div>
            <small className="error">{formErrors.email}</small>

            <div className="signin-forgot-password">
              <p className="err-txt">{erroMsg}</p>
              <p className="success-txt">{msg}</p>
              <div className="signin-submit" onClick={handleSubmit}>
                {forgortPasswordData?.loading ? (
                  <Space size="middle">
                    <Spin size="small" />
                  </Space>
                ) : (
                  "Send"
                )}
              </div>
              <div className="signin-link-to-register">
                <Link to="/signin" reloadDocument>
                  Go Back
                </Link>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="signin-form" style={{ marginTop: 25 }}>
            <div className="signin-email">
              <label htmlFor="signin-email">Enter OTP sent to your email</label>
              <input
                type="text"
                name="code"
                id="signin-email"
                value={code.code}
                onChange={handleOtp}
                style={{ borderColor: "#1e82c5" }}
              />
            </div>
            <div className="signin-forgot-password">
              <p className="err-txt">{erroMsg}</p>
              <p className="success-txt">{msg}</p>
              <div className="signin-submit" onClick={handleSubmit2}>
                {forgortPasswordTokenData?.loading ? (
                  <Space size="middle">
                    <Spin size="small" />
                  </Space>
                ) : (
                  "Send"
                )}
              </div>
              <div className="signin-link-to-register">
                <Link to="/signin" reloadDocument>
                  Go Back
                </Link>
              </div>
            </div>
          </div>
        );
      default:
    }
  };

  console.log(code);

  return (
    <div className="signin-container">
      <div className="signin-row">
        <div className="signin-header">
          <div className="signin-title">TradeGPT</div>
          <div className="signin-body">
            Trusted by millions of users worldwide
          </div>
        </div>

        {PageDisplay()}
      </div>
    </div>
  );
}
