import React, { useEffect, useState } from "react";
import "./admin.scss";
import NotificationsList from "../../components/Admin/Notifications/notificationsList";
import DashboardNav from "../../components/Admin/Nav/dashBoardNav";
import DashboardBody from "../../components/Admin/Dashboard/dashboardBody";
import BottomNav from "../../components/Admin/Nav/bottomNav";

const AdminDashboard = () => {
  return (
    <div className="admin-profile-container">
      <DashboardNav title={"Welcome!"} visible={false} />
      <main>
        <DashboardBody />
      </main>
      <BottomNav />
    </div>
  );
};

export default AdminDashboard;
