import React, { useEffect, useState } from "react";
import MessagesList from "../../components/Admin/Messages/messagesList";
import "./admin.scss";

const Messages = () => {
  return (
    <div className="admin-profile-container">
        <MessagesList />
    </div>
  );
};

export default Messages;
