import React, { useEffect, useState } from "react";
import "./dashboardBody.scss";
import useAdmin from "../../../hooks/useAdmin";
import Avatar from "../../../images/head.58f0e3ba.svg";
import Trash from "../../../images/delete.6215344d.svg";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ReactSearchBox from "react-search-box";
import { useSelector, useDispatch } from "react-redux";
import { fetchInvestor, fetchAllInvestor } from "../../../features/adminSlice";
import { updateAllRoi } from "../../../features/updateRoi/updateRoiSlice";
import { updateAllCommission } from "../../../features/updateRoi/updateCommissionSlice";
// import { toast } from "react-toastify";
import { Space, Spin } from "antd";
import useAxios from "axios-hooks";

const DashboardBody = ({ title }) => {
  const testArr = [1, 2, 3, 4, 5, 6];
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [commissionMsg, setCommissionMsg] = useState("");
  const [roiMsg, setRoiMsg] = useState("");

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const { emailId } = useParams();
  console.log(emailId);

  const { user } = useSelector((state) => state.userReducer);
  console.log(user);
  const { isError, allUsers, isLoading, message } = useSelector(
    (state) => state.allUserSlice
  );

  const {
    handleCreateRoi,
    createRoiData,
    handleCreateCommission,
    createCommissionData,
    getTotalUsersData,
    handleGetTotalUsers,
  } = useAdmin();

  // const { percent } = useSelector((state) => state.updateRoiSlice);
  const { rpercent, rmessage } = useSelector((state) => state.updateRoiSlice);
  const { cpercent, cmessage } = useSelector(
    (state) => state.updateCommissionSlice
  );
  const [commission, setCommission] = useState({ percent: 1 });
  const [roi, setRoi] = useState({ percent: 1 });

  const userDetails = allUsers?.results?.filter((data, idx) => {
    return data.role === null;
  });
  console.log(userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openProfile = (data) => {
    navigate(`/user-profile/${data.email}`, {
      state: {
        fullname: `${data.firstName} ${data.otherName}`,
        email: data.email,
        status: `${data.activation === null ? "Inactive" : "Active"}`,
      },
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const searchUsers = allUsers?.results?.filter((user) => {
    return (
      user?.firstName?.toLowerCase()?.includes(searchTerm) ||
      user?.otherName?.toLowerCase()?.includes(searchTerm)
    );
  });
  // const { handleGetUsers, getUsersData } = useAdmin();

  useEffect(() => {
    handleGetTotalUsers();
  }, []);
  console.log(getTotalUsersData);

  useEffect(() => {
    dispatch(fetchInvestor(user.email));
    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, [isError, user.email, dispatch, message]);

  useEffect(() => {
    dispatch(fetchAllInvestor(user.email));
    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, [isError, user.email, dispatch, message]);

  const handleRoiChange = (e) => {
    const { name, value } = e.target;
    setRoi({ ...roi, [name]: Number(value) });
  };
  console.log(roi);
  console.log(commission);
  const handleCommissionChange = (e) => {
    const { name, value } = e.target;
    setCommission({ ...commission, [name]: Number(value) });
  };

  const onRoiSubmit = (e) => {
    e.preventDefault();
    handleCreateRoi(roi);
    // updateAllRoi(roi);
  };
  const onCommissionSubmit = (e) => {
    e.preventDefault();
    handleCreateCommission(commission);
    // dispatch(updateAllCommission(commission));
  };

  console.log(createCommissionData);

  useEffect(() => {
    if (createCommissionData?.response?.status === 201) {
      setCommissionMsg("Successfully Paid Commission");
      setTimeout(() => {
        setCommissionMsg("");
      }, 2000);
    }
  }, [createCommissionData?.response]);

  useEffect(() => {
    if (createRoiData?.response?.status === 201) {
      setRoiMsg("Successfully Paid Roi");
      setTimeout(() => {
        setRoiMsg("");
      }, 2000);
    }
  }, [createRoiData?.response]);
  return (
    <div className="dashboard-container">
      <div className="dc-box">
        <div className="btn-items">
          <button className="btn-update" onClick={onOpenModal}>
            {" "}
            Update ROI
          </button>
          <button className="btn-update" onClick={onOpenModal2}>
            {" "}
            Update Commission
          </button>
        </div>

        <input
          type="search"
          name=""
          id=""
          className="search-input"
          placeholder="Search users"
          onChange={handleSearch}
        />
      </div>

      <div className="dashboard-users">
        {getTotalUsersData?.loading ? (
          <></>
        ) : (
          <div
            className="du-header"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <h3>
              Total Users:{" "}
              <span>
                {" "}
                {getTotalUsersData?.data &&
                  getTotalUsersData?.data?.data?.total_user}
              </span>{" "}
            </h3>
            <h3>
              {" "}
              Total wallet:{" "}
              <span>
                {" "}
                $
                {getTotalUsersData?.data &&
                  getTotalUsersData?.data?.data?.total_amount}
              </span>{" "}
            </h3>
          </div>
        )}
        
        <div className="du-header">
          <h3> Users </h3>
        </div>
        <div className="du-container">
          {isLoading ? (
            <div
              className="example"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Space size="large">
                <Spin size="large" />
              </Space>
            </div>
          ) : (
            searchUsers?.map((data, idx) => (
              <>
                {data.role === null && (
                  // <Link
                  //   // to={{
                  //   //   pathname: `/user-profile/${data.email}`,
                  //   //   userDetails,
                  //   // }}
                  //   to={`/user-profile/${data.email}`}
                  //   state={{
                  //     data: data.name
                  //   }}
                  //   key={data.id}
                  //   onClick={openProfile}
                  // >
                  <div
                    className="prc-contents"
                    onClick={() => openProfile(data)}
                  >
                    <div className="img-box">
                      <img src={Avatar} alt="" />
                    </div>
                    <div className="prc-details">
                      <h5>
                        {data.firstName} {data.otherName}
                      </h5>
                      <h6>
                        {" "}
                        {data.activation === null ? "Inactive" : "Active"}
                      </h6>
                    </div>
                    <div className="del-trash">
                      <img src={Trash} alt="" />{" "}
                    </div>
                  </div>
                  // </Link>
                )}
              </>
            ))
          )}
        </div>
      </div>
      {open && (
        <Modal open={open} onClose={onCloseModal} center>
          <div className="modal-header">
            <h3>Update ROI</h3>
          </div>

          <div className="modal-body">
            <p className="successTxt"> {roiMsg} </p>
            <label htmlFor=""> Amount </label>
            <input
              type="number"
              min="1"
              name="percent"
              className="form-input"
              value={roi.percent}
              onChange={handleRoiChange}
            />
            <button className="btn" type="submit" onClick={onRoiSubmit}>
              {createRoiData?.loading ? (
                <Space size="small">
                  <Spin size="small" />
                </Space>
              ) : (
                "Publish"
              )}
            </button>
          </div>
        </Modal>
      )}
      {open2 && (
        <Modal open={open2} onClose={onCloseModal2} center>
          <div className="modal-header">
            <h3>Update Commission</h3>
          </div>

          <div className="modal-body">
            {/* <form onSubmit={onCommissionSubmit}> */}
            <p className="successTxt"> {commissionMsg} </p>
            <label htmlFor=""> Amount </label>
            <input
              type="number"
              min="1"
              value={commission.percent}
              className="form-input"
              name="percent"
              onChange={handleCommissionChange}
            />
            <button className="btn" onClick={onCommissionSubmit}>
              {createCommissionData?.loading ? (
                <Space size="small">
                  <Spin size="small" />
                </Space>
              ) : (
                "Publish"
              )}
            </button>
            {/* </form> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DashboardBody;
