import React, { useEffect, useState, useRef } from "react";
import "./profileNav.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import bars from "../../../images/hamburger.15d0622c.svg";

const AdminProfileNav = ({ title, visible = true }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dropbox = useRef(null);
  const { emailId } = useParams();


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (dropbox.current && !dropbox.current.contains(event.target)) {
      setOpen(false);
    }
  };
  return (
    <nav className="top-nav" ref={dropbox}>
      <span>
        <FontAwesomeIcon
          className="bk-btn"
          icon={faArrowLeft}
          onClick={() => navigate(-1)}
        />
      </span>
      <h2> {title} </h2>
      {visible && (
        <>
          <span onClick={(e) => setOpen(!open)}>
            <img src={bars} alt="" />
          </span>

          {open && (
            <div className={`dropbox ${open && "open"}`}>
              <Link to={`/edit-profile/${emailId}`}>
                <p> Edit Profile</p>
              </Link>
              <p> Delete </p>
            </div>
          )}
        </>
      )}
    </nav>
  );
};

export default AdminProfileNav;
