import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUser, allUsers, updateUserWallet } from "../services/apiServices";
import { getUser, allUsersUrl, updateWalletUrl } from "../services/urls";

const initialState = {
    allUsers: "",
    isLoading: false,
	isSuccess: false,
	message: "",
	combined: 0,
	roi: 0,
	commission: 0,
	main: 0,
};


export const updateWallet = createAsyncThunk(updateWalletUrl, async (data, thunkAPI) => {
	try {
        const token = thunkAPI.getState().userReducer.user.token;
		const response = await updateUserWallet(data, token);
		console.log(response)
		return response.data;
	} catch (error) {
		const message = error.response.data.message;
		console.log(message)
		return thunkAPI.rejectWithValue(message);
	}
});


export const updateWalletSlice = createSlice({
	name: "wallet",
	initialState,
	reducers: {
		resetWallet: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateWallet.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateWallet.fulfilled, (state, action) => {
            state.allUsers = action?.payload;
			state.isLoading = false;
			state.isSuccess = true;
			state.roi = action?.payload?.data?.roi;
			state.combined = action?.payload?.data?.combined;
			state.commission = action?.payload?.data?.commission;
            console.log(action.payload);
		});
		builder.addCase(updateWallet.rejected, (state, action) => {
			console.log(action.payload);
			state.isError = true;
			state.isLoading = false;
			state.message = action.payload;
		});
	},
});

export const { resetWallet } = updateWalletSlice.actions;
export default updateWalletSlice.reducer;
