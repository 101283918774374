import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { approveDeposit } from "../../services/apiServices";
import { approveDepositUrl } from "../../services/urls";

const initialState = {
  approveDeposit: "",
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const adminApproveDeposit = createAsyncThunk(
  approveDepositUrl,
  async (ref, email, thunkAPI) => {
    try {
      const token = thunkAPI.getState().userReducer.user.token;
      return await approveDeposit(ref, email, token);
    } catch (error) {
      const message = error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminApproveDepositSlice = createSlice({
  name: "adminApproveDeposit",
  initialState,
  reducers: {
    resetDeposits: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminApproveDeposit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminApproveDeposit.fulfilled, (state, action) => {
      // state.approveDeposit = action?.payload;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminApproveDeposit.rejected, (state, action) => {
      console.table(action.payload);
      state.isError = true;
      state.isLoading = false;
      state.message = action.payload;
    });
  },
});

export const { resetDeposits } = adminApproveDepositSlice.actions;
export default adminApproveDepositSlice.reducer;
