import Footer from "./footer";
import Nav from "./nav";
import "./style.css";
import faq from "../../assets/images/head-faq.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const Faq = () => {
  return (
    <div>
      <Nav />
      <section className="section">
        <div className="container pt-5">
          <div>
            <h2 className="fw-bold text-center josefin">Frequently Asked Questions</h2>
          </div>
          <div className="row py-5 align-items-center">
            <div className="col-lg-6">
              <img src={faq} alt="" width="80%" />
            </div>
            <div className="col-lg-6">
              <div className="accordion" id="accordionExampleY">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOneY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneY"
                      aria-expanded="false"
                      aria-controls="collapseOneY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />
                      1. Is TradeGPT regulated?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseOneY"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOneY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      Yes. <br></br> TradeGPT is registered as BRONZE COIN
                      NETWORKS LTD., Under Commercial Registry Office Macau
                      (CRCRM). <br></br>TradeGPT is regulated by the Australian
                      Securities and Investment Commission (ASIC). Official
                      partners to Tencent and Alibaba group.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwoY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoY"
                      aria-expanded="false"
                      aria-controls="collapseTwoY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />{" "}
                      2. How do I contact TradeGPT??
                    </button>
                  </h2>
                  <div
                    id="collapseTwoY"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwoY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      Our contact info are as follows;<br></br> 508 Zhongshan
                      Avenue, 14th Floor Block 8, Hankou, Macau. <br></br>{" "}
                      Email: info@TradeGPT.uk On social media @TradeGPT
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThreeY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeY"
                      aria-expanded="false"
                      aria-controls="collapseThreeY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />{" "}
                      3. How can I earn on TradeGPT copy trading platform??
                    </button>
                  </h2>
                  <div
                    id="collapseThreeY"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      -To earn on the TradeGPT copy trading platform, visit the
                      website www.TradeGPT.site, <br></br> -Create a
                      trading account and verify your email. <br></br> -Fund
                      your account with a minimum of $100. <br></br> -The system
                      will automatically link your account with professional
                      traders in the financial market. <br></br> -Hence, you can
                      make up to 15% ROI monthly.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThreeY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeY"
                      aria-expanded="false"
                      aria-controls="collapseThreeY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />{" "}
                      4. How does the TradeGPT copy trading platform referral
                      system work??
                    </button>
                  </h2>
                  <div
                    id="collapseThreeY"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      -Log into your trading wallet, copy and share your
                      referral link with friends.<br></br> -When someone
                      registers through your link, you get 10% bonus of their
                      deposit.<br></br>
                      -You will also earn up to 5% referral roi monthly.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThreeY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeY"
                      aria-expanded="false"
                      aria-controls="collapseThreeY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />{" "}
                      5. How do I make deposit on the TradeGPT copy trading
                      platform??
                    </button>
                  </h2>
                  <div
                    id="collapseThreeY"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      -Log into your account and click on fund wallet -Follow
                      the onscreen instructions to complete your deposit.
                      <br></br> -Copy your unique bitcoin wallet address.{" "}
                      <br></br> -Make a deposit of the exact required amount
                      using the wallet address. <br></br> -Input the transaction
                      Id number in the description. <br></br> -Upload proof of
                      payment appropriately. <br></br> -Account funding takes a
                      maximum of one to twelve working hours.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThreeY">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeY"
                      aria-expanded="false"
                      aria-controls="collapseThreeY"
                    >
                      <FontAwesomeIcon
                        className="fa-sm me-2 opacity-70"
                        icon={faQuestionCircle}
                      />{" "}
                      6. How do I withdraw from my wallet??
                    </button>
                  </h2>
                  <div
                    id="collapseThreeY"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeY"
                    data-mdb-parent="#accordionExampleY"
                  >
                    <div className="accordion-body">
                      -Log into your wallet and Click on withdraw funds.{" "}
                      <br></br> -Select account type. <br></br> -Paste your
                      bitcoin wallet address in the provision made. <br></br>{" "}
                      -Confirm to proceed with the withdrawal. <br></br>
                      -Fun Withdrawal takes one to twelve working hours to be
                      completed. <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Faq;
