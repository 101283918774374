import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUser, allDeposit } from "../services/apiServices";
import { allDepositUrl } from "../services/urls";

const initialState = {
    allDeposit: "",
    isLoading: false,
	isSuccess: false,
	message: "",
};

export const fetchAllDeposit = createAsyncThunk(
	allDepositUrl,
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await allDeposit(data, token);
		} catch (error) {
			const message = error.message;
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const allDepositSlice = createSlice({
	name: "usersDeposits",
	initialState,
	reducers: {
		resetDeposits: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllDeposit.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchAllDeposit.fulfilled, (state, action) => {
            state.allDeposit = action?.payload;
			state.isLoading = false;
			state.isSuccess = true;
		});
		builder.addCase(fetchAllDeposit.rejected, (state, action) => {
			console.table(action.payload);
			state.isError = true;
			state.isLoading = false;
			state.message = action.payload;
		});
	},
});

export const { resetDeposits } = allDepositSlice.actions;
export default allDepositSlice.reducer;
