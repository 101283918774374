import React, { useEffect, useState } from "react";
import "./admin.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import WithdrawalReqList from "../../components/Admin/Transactions/WithdrawalReqList";

const WithdrawalReq = () => {
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Withdrawal Requests"} visible={false} />
      <main>
        <WithdrawalReqList />
      </main>
    </div>
  );
};

export default WithdrawalReq;
