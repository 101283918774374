import React, { useEffect, useState } from "react";
import "./admin.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import AdminProfileDetails from "../../components/Admin/Profile/ProfileDetails";
import AdminWalletDetails from "../../components/Admin/Profile/profileWallets";
import AdminReferrals from "../../components/Admin/Profile/referrals";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchInvestor } from "../../features/investorSlice";
import { Space, Spin } from "antd";

const AdminProfile = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { emailId } = useParams();
  console.log(emailId);

  const location = useLocation()
  console.log(location);

  const { user } = useSelector((state) => state.userReducer);
  const { isError, message, combined, roi, commission, main, isLoading } =
    useSelector((state) => state.investorSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInvestor(emailId));
    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, [isError, emailId, dispatch, message]);

  console.log(main)
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Profile"} />
      <main>
        {isLoading ? (
          <div
            className="example"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <>
            <AdminProfileDetails emailId={emailId} location={location} />
            <AdminWalletDetails commission={commission} main={main} roi={roi} />
            <AdminReferrals location={location} />
          </>
        )}
      </main>
    </div>
  );
};

export default AdminProfile;
