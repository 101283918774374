import { Button } from "antd";
import "./ErrorPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceGrimace } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";

const ErrorPage = ({ error, errorInfo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      // navigate("/signin");
      window.location = "https://www.bcnetwork.site/signin"
    }, 1000);
    return () => {};
  }, []);

  return (
    // <div className="error-container">
    //   <div className="error-row">
    //     <h3 style={{ textAlign: "center" }}>Sorry, We have a problem</h3>
    //     <p style={{ textAlign: "center" }}>
    //       <FontAwesomeIcon icon={faFaceGrimace} size="2x" id="icon" />
    //     </p>
    //     <h4 style={{ textAlign: "center" }}>Please kindly Log In again.</h4>
    //     <div className="btn-ctn">
    //       {/* <Button
    //         type="primary"
    //         className="error-btn"
    //         onClick={() => (window.location.href = "/")}
    //       >
    //         Refresh Page
    //       </Button> */}
    //       <Button
    //         className="error-btn"
    //         style={{ lineHeight: 0 }}
    //         onClick={() => window.location.replace("/signin")}
    //       >
    //         Log In
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    <>
    </>
  );
};
export default ErrorPage;
