import React, { useEffect, useState } from "react";
import "./usersTransactions.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import DepositHistoryList from "../../components/Users/DepositHistoryList";
import Avatar from "../../images/head.58f0e3ba.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchInvestor, fetchAllInvestor } from "../../features/adminSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const UserReferrals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userReducer);

  const { isError, message, allUsers, isLoading } = useSelector(
    (state) => state.allUserSlice
  );

  const { combined, roi, commission, main } = useSelector(
    (state) => state.investorSlice
  );
  const referrals = allUsers?.results?.filter((data, idx) => {
    return data.upliner === user?.email;
  });
  useEffect(() => {
    dispatch(fetchAllInvestor(user?.email));
    // dispatch(fetchInvestor(emailId));

    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, []);

  console.log(referrals);
  return (
    <div className="user-transactions-container">
      <div className="change-password-nav">
        <span>
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => navigate("/profile")}
          />
        </span>
        <h3> Referrals</h3>
      </div>
      <div>
        <div className="pr-container">
          <p className="ref-link-title"> Your Referral code: </p>

          <p className="ref-link">{`https://www.tradegptai.pro/register/?ref=${user?.email}`}</p>

          {referrals?.map((data, idx) => (
            <div className="prc-contents" key={idx}>
              <div className="img-box">
                <img src={Avatar} alt="" />
              </div>
              <div className="prc-details">
                <h5>
                  {data.firstName} {data.otherName}
                </h5>
                <h6>
                  Referral
                  {/* {data.email} */}
                  {/* {dispatch(fetchInvestor(data.email))} */}
                </h6>
              </div>
            </div>
          ))}
          {!referrals?.length && (
            <h3
              style={{
                textAlign: "center",
                fontSize: 30,
                paddingTop: 10,
                paddingBottom: 15,
              }}
            >
              No Referrals
            </h3>
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default UserReferrals;
