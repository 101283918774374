import React, { useEffect, useState } from "react";
import "./transactions.scss";
import Avatar from "../../../images/profilePic.png";
import { Link } from "react-router-dom";
import { Space, Spin, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchAllWithdrawal } from "../../../features/allWithdrawalSlice";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import useAdmin from "../../../hooks/useAdmin";

const WithdrawalReqList = ({ title }) => {
  const testArr = [1, 2, 3];
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  const { isError, message, allWithdrawal, isLoading } = useSelector(
    (state) => state.allWithdrawalSlice
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const {
    handleGetWithdrawals,
    getwithdrawalsData,
    handleUpdateUserWithdrawal,
    handleDeclineUserWithdrawal,
    declineUserWithdrawalData,
  } = useAdmin();

  useEffect(() => {
    // dispatch(fetchAllWithdrawal());
    // if (isError) {
    //   toast.error(`Error! ${message}`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }

    handleGetWithdrawals();
  }, []);

  const myWithdrawals = getwithdrawalsData?.data?.data?.filter((data, idx) => {
    return data.email === user.email;
  });
  console.log(myWithdrawals);
  console.log(getwithdrawalsData);
  console.log(declineUserWithdrawalData);
  console.log(getwithdrawalsData?.data);

  return (
    <div className="drl-container">
      {getwithdrawalsData?.loading ? (
        <div
          className="example"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>s/n</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Payto</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {getwithdrawalsData?.data?.data?.reverse()?.map((data, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{data.email}</td>
                <td>{formatter.format(data.amount)}</td>
                <td>{data.payto}</td>
                <td>{data.status}</td>
                <td style={{ display: "flex" }}>
                  <button
                    className="approve-btn"
                    style={{
                      background: data.status === "approved" && "#f7f7f7",
                      color: data.status === "approved" && "#000",
                      display: data.status === "decline" && "none",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    disabled={data.status === "approved"}
                    onClick={() =>
                      handleUpdateUserWithdrawal(data.ref, {
                        ref: data.ref,
                      })
                    }
                  >
                    {data.status === "approved" ? "approved" : "Approve"}
                  </button>
                  <button
                    className="decline-btn"
                    style={{
                      background:
                        data.status === "decline" && "#c10000",
                      color: data.status === "decline" && "#fff",
                      display: data.status === "approved" && "none",
                      marginLeft: "auto",
                      marginRight: "auto"

                    }}
                    disabled={data.status === "decline"}
                    onClick={() =>
                      handleDeclineUserWithdrawal(data.ref, {
                        ref: data.ref,
                      })
                    }
                  >
                    {data.status === "decline"
                      ? "declined"
                      : "Decline"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* <Table dataSource={dataSource} columns={columns} />; */}
      {!getwithdrawalsData?.loading &&
        getwithdrawalsData?.data?.length === 0 && (
          <h2 style={{ textAlign: "center", marginTop: 100 }}>
            No Withdrawal Requests
          </h2>
        )}
    </div>
  );
};

export default WithdrawalReqList;
