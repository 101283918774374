import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { payRoiUrl } from "../../services/urls";
import { payRoi } from "../../services/apiServices";

const initialState = {
	rpercent: 1,
	isError: false,
	isLoading: false,
	isSuccess: false,
	rmessage: "",
};
export const updateAllRoi = createAsyncThunk(
	payRoiUrl,
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await payRoi(data, token);
		} catch (error) {
			const rmessage = error.message;
			return thunkAPI.rejectWithValue(rmessage);
		}
	}
);

const updateRoiSlice = createSlice({
	name: "roiSlice",
	initialState,
	reducers: {
		resetDeposit: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.rpercent = 1;
			state.rmessage = "";
		},
		setDeposit: (state, action) => {
			state.rpercent = parseInt(action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateAllRoi.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateAllRoi.fulfilled, (state, action) => {
			state.isLoading = false;
			state.rmessage = action.payload;
			state.isSuccess = true;
		});
		builder.addCase(updateAllRoi.rejected, (state, action) => {
			state.isLoading = false;
			state.rmessage = action.payload;
			state.isError = true;
		});
	},
});

export const { resetDeposit, setDeposit } = updateRoiSlice.actions;
export default updateRoiSlice.reducer;
