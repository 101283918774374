import React, { useEffect, useState } from "react";
import "./profileDetails.scss";
import Avatar from "../../../images/profilePic.png";
import { useSelector, useDispatch } from "react-redux";
import Avatar2 from "../../../images/head.58f0e3ba.svg";
import { Link, useNavigate, useParams } from "react-router-dom";

const AdminReferrals = ({ location }) => {
  const testArr = [1, 2, 3, 4, 5, 6];

  const { isError, message, allUsers, isLoading } = useSelector(
    (state) => state.allUserSlice
  );

  const referrals = allUsers?.results?.filter((data, idx) => {
    return data.upliner === location.state.email;
  });
  console.log(referrals);
  const navigate = useNavigate();

  const openProfile = (data) => {
    navigate(`/user-profile/${data.email}`, {
      state: {
        fullname: `${data.firstName} ${data.otherName}`,
        email: data.email,
        status: `${data.activation === null ? "Inactive" : "Active"}`,
      },
    });
  };
  return (
    <div className="profile-referrals">
      <div className="pr-header">
        <h3> Referrals </h3>
      </div>
      <div className="pr-container">
        {referrals?.map((data, idx) => (
          <div
            className="prc-contents"
            key={idx}
            onClick={() => openProfile(data)}
          >
            <div className="img-box">
              <img src={Avatar2} alt="" />
            </div>
            <div className="prc-details">
              <h5>
                {data.firstName} {data.otherName}{" "}
              </h5>
              <h6> Referral</h6>
            </div>
          </div>
        ))}
        {!referrals?.length && (
          <h3
            style={{
              textAlign: "center",
              fontSize: 30,
              paddingTop: 10,
              paddingBottom: 15,
            }}
          >
            {" "}
            No Referrals
          </h3>
        )}
      </div>
    </div>
  );
};

export default AdminReferrals;
