import Nav from "./nav";
import Footer from "./footer";
import "./style.css";
import about from "../../assets/images/about.jpg" 

const About = () => {
  return (
    <div>
      <Nav />

      <section className="section">
        <div className="container">
          <div className="text-center fw-bold section-header">
            <h2 className="fw-bold">About us</h2>
          </div>
          <div className="row py-4">
            <div className="col-lg-4">
              <img src={about} alt="" width="100%" />
            </div>
            <div className="col-lg-8">
              <div className="containers mt-5">
                <p>
                TradeGPT is a copy trading platform created to help less
                  experienced traders earn up to 15% of their capital monthly
                  through copying the trades of professional traders in the
                  financial market.
                </p>
                <p>
                  After a deep research by three of the most profitable traders
                  of all time; John Wickham, Joel Taichi, and Sung de Park about
                  the reality that less than 5% of traders make consistent
                  profit in the financial market, TradeGPT was created by the
                  trio, to duplicate the profitability of these few successful
                  traders among many.
                </p>
                <p>
                  Founded in 2019, TradeGPT now have a client base of over 1
                  million institutional and private investors in over 80
                  countries around the word. TradeGPT referral system allows
                  clients to earn 5% referral roi monthly from each of their
                  referrals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
