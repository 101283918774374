import React, { useEffect, useState } from "react";
import "./userReferrals.scss";
import Avatar from "../../images/profilePic.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchInvestor, fetchAllInvestor } from "../../features/adminSlice";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

const UserReferrals = ({ user }) => {
  const testArr = [1, 2, 3, 4, 5, 6];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, message, allUsers, isLoading } = useSelector(
    (state) => state.allUserSlice
  );

  const referrals = allUsers?.results?.filter((data, idx) => {
    return data.upliner === user?.email;
  });
  useEffect(() => {
    dispatch(fetchAllInvestor(user?.email));
    if (isError) {
      // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, []);

  const openProfile = (data) => {
    navigate(`/referrals`, {});
  };

  console.log(referrals);

  return (
    <div className="user-profile-referrals">
      <div className="upr-header-top">
      <p className="ref-link-title"> Your Referral code: </p>
        <p className="ref-link">{`https://www.tradegptai.pro/register/?ref=${user?.email}`}</p>
      </div>
      <div className="upr-header" onClick={() => openProfile()}>
        <h3>Total Referrals </h3>
        <h2>{referrals?.length} </h2>
      </div>
    </div>
  );
};

export default UserReferrals;
