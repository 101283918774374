import React, { createContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [formData1, setFormData1] = useState({
    firstName: "",
    otherName: "",
    phone: "",
    userType: "",
    email: "",
    password: "",
    confirm_password: "",
    upliner: "",
  });
  const handleInputs = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };
  const clearInputs = () => {
    setFormData1({
      firstName: "",
      otherName: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
    });
  };

  const [email, setEmail] = useState({
    email: "",
  });

  const [dataToken, setDataToken] = useState({
    token: "",
  });
  return (
    <UserContext.Provider
      value={{
        formData1,
        handleInputs,
        clearInputs,
        setFormData1,
        email,
        setEmail,
		dataToken,
		setDataToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export default UserContext;
