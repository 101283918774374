export const Home = ({ width, height, fill }) => (
  <svg
    width={width || "23"}
    height={height || "22"}
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7173 11.3564L11.8173 0.348403C11.4004 -0.116134 10.5996 -0.116134 10.1827 0.348403L0.282682 11.3564C0.140184 11.5145 0.0466493 11.7105 0.0134509 11.9208C-0.0197476 12.1311 0.00882062 12.3465 0.0956821 12.5408C0.271682 12.9382 0.665482 13.1936 1.09998 13.1936H3.29998V20.8992C3.29998 21.1912 3.41587 21.4711 3.62216 21.6776C3.82845 21.884 4.10824 22 4.39998 22H7.69998C7.99172 22 8.27151 21.884 8.4778 21.6776C8.68409 21.4711 8.79998 21.1912 8.79998 20.8992V16.496H13.2V20.8992C13.2 21.1912 13.3159 21.4711 13.5222 21.6776C13.7285 21.884 14.0082 22 14.3 22H17.6C17.8917 22 18.1715 21.884 18.3778 21.6776C18.5841 21.4711 18.7 21.1912 18.7 20.8992V13.1936H20.9C21.113 13.1945 21.3217 13.1334 21.5006 13.0176C21.6795 12.9019 21.8209 12.7366 21.9076 12.5418C21.9942 12.3471 22.0224 12.1313 21.9886 11.9208C21.9548 11.7103 21.8605 11.5142 21.7173 11.3564Z"
      fill="#5DB075"
    />
  </svg>
);
export const Bell = ({ width, height, fill }) => (
  <svg
    width={width || "18"}
    height={height || "20"}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3333 10.3907V8.66667H14V10.6667C14 10.8435 14.0703 11.013 14.1953 11.138L16 12.9427V14H1.33333V12.9427L3.138 11.138C3.26303 11.013 3.3333 10.8435 3.33333 10.6667V8C3.33148 7.0632 3.57687 6.14249 4.04472 5.33088C4.51258 4.51927 5.18633 3.84549 5.99793 3.3776C6.80953 2.90972 7.73022 2.66429 8.66703 2.66612C9.60383 2.66795 10.5236 2.91696 11.3333 3.388V1.898C10.6987 1.61701 10.0238 1.43792 9.33333 1.36733V0H8V1.36667C6.35638 1.53396 4.83317 2.30474 3.72491 3.52999C2.61665 4.75523 2.00207 6.34789 2 8V10.3907L0.195333 12.1953C0.0703004 12.3203 3.77583e-05 12.4899 0 12.6667V14.6667C0 14.8435 0.0702379 15.013 0.195262 15.1381C0.320286 15.2631 0.489856 15.3333 0.666667 15.3333H5.33333V16C5.33333 16.8841 5.68452 17.7319 6.30964 18.357C6.93477 18.9821 7.78261 19.3333 8.66667 19.3333C9.55072 19.3333 10.3986 18.9821 11.0237 18.357C11.6488 17.7319 12 16.8841 12 16V15.3333H16.6667C16.8435 15.3333 17.013 15.2631 17.1381 15.1381C17.2631 15.013 17.3333 14.8435 17.3333 14.6667V12.6667C17.3333 12.4899 17.263 12.3203 17.138 12.1953L15.3333 10.3907ZM10.6667 16C10.6667 16.5304 10.456 17.0391 10.0809 17.4142C9.70581 17.7893 9.1971 18 8.66667 18C8.13623 18 7.62753 17.7893 7.25245 17.4142C6.87738 17.0391 6.66667 16.5304 6.66667 16V15.3333H10.6667V16Z"
      fill="#110C0C"
    ></path>
    <path
      d="M15.3333 7.33333C16.8061 7.33333 18 6.13943 18 4.66667C18 3.19391 16.8061 2 15.3333 2C13.8605 2 12.6666 3.19391 12.6666 4.66667C12.6666 6.13943 13.8605 7.33333 15.3333 7.33333Z"
      fill="#5DB075"
      stroke="#C4C4C4"
    ></path>
  </svg>
);
export const Exit = ({ width, height, fill }) => (
  <svg
    width={width || "20"}
    height={height || "18"}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65556 13.29C9.08889 13.68 9.78889 13.68 10.2222 13.29L14.2111 9.7C14.3141 9.60749 14.3958 9.4976 14.4516 9.37662C14.5074 9.25565 14.536 9.12597 14.536 8.995C14.536 8.86403 14.5074 8.73435 14.4516 8.61338C14.3958 8.4924 14.3141 8.38251 14.2111 8.29L10.2222 4.7C10.0145 4.51302 9.7327 4.40798 9.43889 4.40798C9.14508 4.40798 8.86331 4.51302 8.65556 4.7C8.4478 4.88698 8.33109 5.14057 8.33109 5.405C8.33109 5.66943 8.4478 5.92302 8.65556 6.11L10.7444 8H1.11111C0.5 8 0 8.45 0 9C0 9.55 0.5 10 1.11111 10H10.7444L8.65556 11.88C8.22222 12.27 8.23333 12.91 8.65556 13.29ZM17.7778 0H2.22222C1.63285 0 1.06762 0.210714 0.650874 0.585786C0.234126 0.960859 0 1.46957 0 2V5C0 5.55 0.5 6 1.11111 6C1.72222 6 2.22222 5.55 2.22222 5V3C2.22222 2.45 2.72222 2 3.33333 2H16.6667C17.2778 2 17.7778 2.45 17.7778 3V15C17.7778 15.55 17.2778 16 16.6667 16H3.33333C2.72222 16 2.22222 15.55 2.22222 15V13C2.22222 12.45 1.72222 12 1.11111 12C0.5 12 0 12.45 0 13V16C0 17.1 1 18 2.22222 18H17.7778C19 18 20 17.1 20 16V2C20 0.9 19 0 17.7778 0Z"
      fill="#110C0C"
    />
  </svg>
);
