import React, { useEffect, useState } from "react";
import "./profileDetails.scss";
import Avatar from "../../../images/head.58f0e3ba.svg";
import { Link, useParams } from "react-router-dom";

const AdminProfileDetails = ({ emailId, location }) => {
  return (
    <div className="profile-container">
      <div className="pc-detail-box">
        <div className="img-box">
          <img src={Avatar} alt="" />
        </div>
        <h4>{location.state.fullname}</h4>
        <h6>{emailId}</h6>
        <span
          style={{
            backgroundColor: location.state.status === "Inactive" && "#F42C2C",
          }}
        >
          {location.state.status}
        </span>
      </div>
    </div>
  );
};

export default AdminProfileDetails;
