import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../Context/UserRegister";
import "./Signup.css";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import Signup from "./Signup";
import Signup2 from "./Signup2";

export default function Register() {
  const [step, setStep] = useState(1);

  const { search } = useLocation();
  const { ref } = qs.parse(search);

  const [formData1, setFormData1] = useState({
    firstName: "",
    otherName: "",
    phone: "",
    userType: "",
    email: "",
    password: "",
    confirm_password: "",
    upliner: "",
  });

  console.log(formData1);

  const handleInputs = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.value,
    });
  };
  const clearInputs = () => {
    setFormData1({
      firstName: "",
      otherName: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
    });
  };
  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    setFormData1({
      ...formData1,
      upliner: ref || "",
    });
  }, []);

  const PageDisplay = () => {
    switch (step) {
      case 1:
        return (
          <Signup
            handleInputs={handleInputs}
            formData1={formData1}
            nextStep={nextStep}
          />
        );

      case 2:
        return (
          <Signup2
            clearInputs={clearInputs}
            handleInputs={handleInputs}
            formData1={formData1}
          />
        );
      default:
    }
  };

  return <> {PageDisplay()}</>;
}
