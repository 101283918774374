import React, { useEffect, useState, useRef } from "react";
import "./profileNav.scss";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBank,
  faBell,
  faCog,
  faMoneyBill,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../../images/head.58f0e3ba.svg";
import { Bell } from "../../../images/icons";
import { Drawer } from "antd";
import bars from "../../../images/hamburger.15d0622c.svg";
import { expiredToken, setProfile } from "../../../features/userSlice";

const DashboardNav = ({ title }) => {
  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const handleMenu = () => {
    setVisible(true);
  };
  const dispatch = useDispatch();

  return (
    <nav className="dashboard-nav">
      <h2> {title} </h2>
      <div className="nav-contents">
        {/* <Link to="/notifications"> */}
        <Bell />
        {/* </Link> */}
        <div className="" onClick={handleMenu}>
          <img src={bars} alt="" />
        </div>
        <Drawer
          placement="right"
          onClose={onClose}
          visible={visible}
          width={250}
          // style={{ backgroundColor: "#0B5BA1" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link to="/deposit-requests" className="nav-link">
              <FontAwesomeIcon className="nav-link-icon" icon={faMoneyBill} />
              Deposits
            </Link>
            <>
              <Link to="/withdrawal-requests" className="nav-link">
                <FontAwesomeIcon className="nav-link-icon" icon={faBank} />
                Withdrawals
              </Link>
            </>
            <Link to="/create-admin" className="nav-link">
              <FontAwesomeIcon className="nav-link-icon" icon={faUser} />
              Create Admin
            </Link>
            {/* <Link to="/site-settings" className="nav-link">
            <FontAwesomeIcon
                className="nav-link-icon"
                icon={faCog}
              />
              Settings
            </Link> */}
            <Link
              to="/"
              className="nav-link"
              onClick={() => {
                dispatch(expiredToken());
                dispatch(setProfile(null));
              }}
            >
              <FontAwesomeIcon className="nav-link-icon" icon={faSignOut} />
              Logout
            </Link>
          </div>
        </Drawer>
      </div>
    </nav>
  );
};

export default DashboardNav;
