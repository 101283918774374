import React, { useEffect, useState, useRef } from "react";
import "./profileNav.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Bell, Exit, Home } from "../../../images/icons";

const BottomNav = () => {
  return (
    <nav className="mobile-bottom-nav">
      <div className="mobile-bottom-nav__item mobile-bottom-nav__item--active">
        <div className="mobile-bottom-nav__item-content">
          {/* <Link to="/notifications"> */}
            <Bell />
          {/* </Link> */}
        </div>
      </div>
      <div className="mobile-bottom-nav__item">
        <div className="mobile-bottom-nav__item-content">
          <Home />
        </div>
      </div>
      <div className="mobile-bottom-nav__item">
        <div className="mobile-bottom-nav__item-content">
          <Link to="/signin">
            <Exit />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default BottomNav;
