import React, { useEffect, useState } from "react";
import "./history.scss";
import { Space, Spin, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { fetchAllDepositHistory } from "../../features/depositHistorySlice";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { fetchAllDeposit } from "../../features/allDepositSlice";

const DepositHistoryList = ({ title }) => {
  const testArr = [1, 2, 3];
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  // const { isError, message, allHistory, isLoading } = useSelector(
  //   (state) => state.allDepositHistorySlice
  // );
  const { isError, message, allDeposit, isLoading } = useSelector(
    (state) => state.allDepositSlice
  );

  useEffect(() => {
    // dispatch(fetchAllDepositHistory(user.email));
    dispatch(fetchAllDeposit());

    if (isError) {
      // toast.error(`Error! No deposit history found`, {
      // // toast.error(`Error! ${message}`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  }, []);

  const myDeposits = allDeposit?.data?.filter((data, idx) => {
    return data.email === user.email;
  });
  console.log(myDeposits);

  return (
    <div className="dhl-container">
      {isLoading ? (
        <div
          className="example"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Space size="large">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Transaction Id</th>
                <th>Amount</th>
                <th>Image</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* {allHistory?.map((data, idx) => ( */}
              {myDeposits?.reverse()?.map((data, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{data?.ref}</td>
                  <td>${data?.amount}</td>
                  <td>
                    <PhotoProvider>
                      <PhotoView src={data?.poe}>
                        <img
                          className="table-img"
                          src={data?.poe}
                          alt="deposit img"
                        />
                      </PhotoView>
                    </PhotoProvider>{" "}
                  </td>
                  <td>
                    <span
                      // className="approve-btn"
                      className={`${
                        data?.status === "pending" ? "pending" : "confirmed"
                      } approve-btn`}
                    >
                      {data?.status}
                    </span>
                  </td>
                </tr>
              ))}
              {/* // ))} */}
            </tbody>
          </table>
          {/* {!isLoading && !allHistory?.data && (
            <h2 style={{ textAlign: "center", marginTop: 100 }}>
              No Deposit History{" "}
            </h2>
          )} */}
        </>
      )}
      {/* <Table dataSource={dataSource} columns={columns} />; */}
    </div>
  );
};

export default DepositHistoryList;
