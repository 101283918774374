import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { payCommissionUrl,  } from "../../services/urls";
import { payCommission } from "../../services/apiServices";

const initialState = {
	cpercent: 1,
	isError: false,
	isLoading: false,
	isSuccess: false,
	cmessage: "",
};
export const updateAllCommission = createAsyncThunk(
	payCommissionUrl,
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await payCommission(data, token);
		} catch (error) {
			const cmessage = error.message;
			return thunkAPI.rejectWithValue(cmessage);
		}
	}
);

const updateCommissionSlice = createSlice({
	name: "commissionSlice",
	initialState,
	reducers: {
		resetDeposit: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.cpercent = 1;
			state.cmessage = "";
		},
		setDeposit: (state, action) => {
			state.cpercent = parseInt(action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateAllCommission.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateAllCommission.fulfilled, (state, action) => {
			state.isLoading = false;
			state.cmessage = action.payload;
			state.isSuccess = true;
		});
		builder.addCase(updateAllCommission.rejected, (state, action) => {
			state.isLoading = false;
			state.cmessage = action.payload;
			state.isError = true;
		});
	},
});

export const { resetDeposit, setDeposit } = updateCommissionSlice.actions;
export default updateCommissionSlice.reducer;
