import Nav from "./nav";
import Footer from "./footer";
import "./style.css";
import about from "../../assets/images/about.jpg";

const Terms = () => {
  return (
    <div>
      <Nav />

      <section className="section">
        <div className="container">
          <div className="text-center fw-bold section-header">
            <h2 className="fw-bold josefin">Terms and condition of TradeGPT </h2>
          </div>
          <div className="row py-2">
            {/* <div className="col-lg-4">
              <img src={about} alt="" width="100%" />
            </div> */}
            <div className="col-lg-8">
              <div className="containers mt-5">
                <p>1. Minimum deposit required to activate account is $50</p>
                <p>
                  2. Inactive accounts means dormant commission and ROI account
                  even though with referrals. 
                </p>
                <p>
                  3. ROI and commission are due on corresponding account
                  activation date. 
                </p>
                <p>4. Deposit and withdrawal are solely with bitcoin. </p>
                <p>5. Deposit and withdrawal is processed within 24 hours</p>
                <p>
                  6. There is no withdrawal limit as long as the withdrawal
                  charges are covered. 
                </p>
                <p>7. There is no link to an external site on the platform</p>
                <p>
                  8. Due to high volatility in the financial markets ROI may vary
                  between 5%-15% and referral commission between 2%-5% on
                  monthly basis. 
                </p>
                <p>
                  9. Withdrawal of trading capital is due after one month of
                  account activation and client must have at least 1 active
                  referral with equivalent equity amount. 
                </p>
              </div>
            </div>
          </div>
          <div className="text-center fw-bold section-header">
            <h2 className="fw-bold josefin">DISCLAIMER </h2>
          </div>
          <p> Trading indices, commodities, forex, stocks,
          cryptocurrencies, CFD's and other financial instruments on margin
          carries high level of risk and may not be suitable for all investors.
          High degree of leverage can work in your favour as you could profit in
          excess of deposit. You could also sustain losses in excess of deposit
          should leverage work against you. It is important to be aware and
          fully understand all risks associated with the financial market before
          trading/investing. </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Terms;
