import React, { useEffect, useState } from "react";
import "./messagesList.scss";
import Avatar from "../../../images/head.58f0e3ba.svg";
import Trash from "../../../images/delete.6215344d.svg";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const MessagesList = ({ title }) => {
  return (
    <div className="messages-container">
      <nav>
        <div className="prc-contents">
          <div className="img-box">
            <img src={Avatar} alt="" />
          </div>
          <div className="prc-details">
            <h5> Dindu Okonji </h5>
            <h6> Active</h6>
          </div>
        </div>
      </nav>
      <main>
        
      </main>
    </div>
  );
};

export default MessagesList;
