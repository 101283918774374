import React, { useEffect, useState } from "react";
import "./admin.scss";
import AdminProfileNav from "../../components/Admin/Nav/profileNav";
import NotificationsList from "../../components/Admin/Notifications/notificationsList";

const Notifications = () => {
  return (
    <div className="admin-profile-container">
      <AdminProfileNav title={"Notifications"} visible={false} />
      <main>
        {/* <NotificationsList /> */}
      </main>
    </div>
  );
};

export default Notifications;
