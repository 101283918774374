import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allWithdrawal } from "../services/apiServices";
import { allWithdrawalUrl } from "../services/urls";

const initialState = {
    allWithdrawal: "",
    isLoading: false,
	isSuccess: false,
	message: "",
};

export const fetchAllWithdrawal = createAsyncThunk(
	allWithdrawalUrl,
	async (data, thunkAPI) => {
		try {
			const token = thunkAPI.getState().userReducer.user.token;
			return await allWithdrawal(data, token);
		} catch (error) {
			const message = error.message;
			return thunkAPI.rejectWithValue(message);
		}
	}
);

export const allWithdrawalSlice = createSlice({
	name: "usersWithdrawals",
	initialState,
	reducers: {
		resetWithdrawals: (state) => {
			state.isError = false;
			state.isLoading = false;
			state.isSuccess = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllWithdrawal.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchAllWithdrawal.fulfilled, (state, action) => {
            state.allWithdrawal = action?.payload;
			state.isLoading = false;
			state.isSuccess = true;
		});
		builder.addCase(fetchAllWithdrawal.rejected, (state, action) => {
			console.table(action.payload);
			state.isError = true;
			state.isLoading = false;
			state.message = action.payload;
		});
	},
});

export const { resetWithdrawals } = allWithdrawalSlice.actions;
export default allWithdrawalSlice.reducer;
